import Api from 'api'
const api = new Api();

export const metaTagsModule = {
    actions: {
        updateMetaTag (store, {tag, locale}) {
            return new Promise(async (resolve, reject) => {
                try {
                    tag.locale = locale
                    await api.put(`/meta-tags/${tag.id}`, tag)
                    resolve()
                } catch (error) {
                    reject(error)
                }
            })
        },

        createMetaTag (store, {tag, locale}) {
            return new Promise(async (resolve, reject) => {
                try {
                    const { data } =  await api.post('/meta-tags', { ...tag, locale })
                    resolve(data)
                } catch (error) {
                    reject(error)
                }
            })
        },

        removeMetaTag (store, id) {
            return new Promise(async (resolve, reject) => {
                try {
                    await api.delete(`/meta-tags/${id}`)
                    resolve()
                } catch (error) {
                    reject(error)
                }
            })
        }
    }
}
