export default class LinksFilesRefs {
	constructor (editor) {
		this.editor = editor
	}

	createHTML () {
		return `<div>{{ LINKS_FILES:refs[] }}</div>`
	}

	onAction () {
		this.editor.insertContent(this.createHTML(), {})
	}

	get options () {
		const button = this
		return {
			type: 'menuitem',
			text: 'Ссылки файлов',
			onAction: button.onAction.bind(button)
		}
	}
}
