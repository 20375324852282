import config from "utils/config";
import axios from 'axios'
export const documentsModule = {
	state: () => {},
	getters: {},
	mutations: {},
	actions: {
		removeDocument (store, path) {
			return new Promise ((resolve) => {
				resolve(axios.delete(`/api/documents?url=${path}`, {
					headers: {
						'Authorization': `Bearer ${localStorage.getItem('access_token')}`
					}
				}))
			})
		},
		loadDocument (store, { data, path }) {
			return new Promise(async (resolve) => {
				const result = await axios.post(`/api/documents?path=${path}`, data, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'Authorization': `Bearer ${localStorage.getItem('access_token')}`
					}
				})
				resolve(`${config.app.url}/storage/` + result.data)
			})
		}
	}
}
