import Api from 'api'
const api = new Api();

export const state = () => ({})

export const getters = {

}

export const mutations = {

}

export const actions = {
    removeAnswer (store, { question, answer }) {
        return new Promise( async (resolve, reject) => {
            try {
                if (answer?.id) {
                    await api.delete(`/questions/${question.id}/answer/${answer.id}`)
                }
                resolve()
            } catch (error) {
                reject(error)
            }
        })
    },
    removeQuestion (store, question) {
        return new Promise( async (resolve, reject) => {
            try {
                await api.delete(`/questions/${question.id}`)
                resolve()
            } catch (error) {
                reject(error)
            }
        })
    },
    updateQuestion (store, question) {
        return new Promise( async (resolve, reject) => {
            try {
                await api.put(`/questions/${question.id}`, question)
                resolve()
            } catch (error) {
                reject(error)
            }
        })
    },
    updateAnswer (store, answer) {
        return new Promise( async (resolve, reject) => {
            try {
                const {data} = await api.put(`/questions/${answer.question_id}/answer`, answer)
                resolve(data)
            } catch (error) {
                reject(error)
            }
        })
    },
    loadQuestion (store, id) {
        return new Promise (async (resolve, reject) => {
            try {
                const {data} = await api.get(`/questions/${id}`)
                resolve(data)
            } catch (error) {
                reject(error)
            }
        })
    },
    getQuestions (store, isPublished) {
        return new Promise (async (resolve, reject) => {
            try {
                const {data} = await api.get('/questions' + (isPublished ? '/published' : ''))
                resolve(data)
            } catch (error) {
                reject(error)
            }
        })
    }
}

export const questionsModule = {
    state, getters, mutations, actions
}
