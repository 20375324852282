const axios = require('axios')
import router from '../router/router'

export default class Api {
    get _token () {
        return localStorage.getItem('access_token')
    }

    get _instance() {
        const instance = axios.create({
            baseURL: '/api',
            headers: {
                Authorization: `Bearer ${this._token}`
            }
        })
        instance.interceptors.response.use(response => response, (error) => {
            console.log(error.response)
            if (error.response.status === 401) {
                router.push('/login')
            }
            return Promise.reject(error)
        })
        return instance
    }

    post (url, data, config) {
        return this._instance.post(url, data, config)
    }

    put (url, data, config) {
        return this._instance.put(url, data, config)
    }

    delete (url, config) {
        return this._instance.delete(url, config)
    }

    get (url, config) {
        return this._instance.get(url, config)
    }
}
