import Api from 'api'
const api = new Api()
export const state = () => ({
	redirects: []
})


export const actions = {
	getRedirects (store, page) {
		return api.get(`pages/${page.id}/redirects`)
	},
	createRedirect (store, { page, redirect }) {
		return api.post(`pages/${page.id}/redirects`, redirect)
	},
	removeRedirect (store, { page, redirect }) {
		return api.delete(`pages/${page.id}/redirects/${redirect.id}`)
	}
}

export const redirectModule = {
	state,
	actions
}
