<template>
  <div>
    <div class="section-content">
      <ItemsSidebar
        @scrollend="getPages"
        @search="onSearch"
				:search="pageSearch"

				:filter="pageFilter.value"
				@filter="onFilter"
				:filter-fields="pagesFilterFields"

        :prevent-default-search="true"
        class="pages-list"
        :items="pages"
        empty-list-text="Страницы отсутствуют"
        title="Список страниц"
        :box="{ out: true }"
        @submit="setSectionChildren(pageChildrenOptions)"
      >
        <template v-slot:default="{ item }">
          <ItemsSidebarItem
            :is-active="selectedPageIds.includes(item.id)"
            :item="item"
            @click="selectPage"
          >
            <template #icon>
							<i class="bx bx-book-content" v-if="item.type === 'custom'"></i>
							<i class="bx bx-news" v-else-if="item.type === 'news'"></i>
							<i class="bx bx-star" v-else-if="item.type === 'special'"></i>
            </template>
            <template #text>
              <span class="text-overflow">{{
                item.name_ru || item.name_en
              }}</span>
            </template>
						<template #appender>
							<span class="slug ml-auto" :title="`/${getPageSlug(item)}`">
								<router-link :to="`/pages/${getPageSlug(item)}`">
									{{ `/${getPageSlug(item)}` }}
								</router-link>
							</span>
						</template>
          </ItemsSidebarItem>
        </template>
      </ItemsSidebar>
      <TreeSelector
        :tree="tree"
        class="tree-picker"
        :title="sectionTitle"
        @pages:remove="removePages"
        @select:node="setCurrentNode"
        @remove:node="removeNode"
        @children:set="setSectionChildren"
        ref="tree_selector"
      ></TreeSelector>
    </div>
    <div class="page-buttons">
      <b-button
        @click="setSectionOptions"
        class="mt-2 ml-auto"
        variant="primary"
        :disabled="buttonDisabled"
      >
        Сохранить
      </b-button>
      <b-button
        @click="openRemoveSectionModal"
        v-if="isEdit"
        class="mt-2 ml-auto"
        variant="danger"
        :disabled="buttonDisabled"
      >
        Удалить секцию
      </b-button>
    </div>
    <b-modal ref="removeSectionModal" title="Удаление секции" v-if="isEdit">
      <div class="d-block">
        <h3>Вы уверены, что хотете удалить секцию "{{ section.name_ru }}"?</h3>
      </div>
      <template #modal-footer>
        <b-button class="mt-3 mr-3" @click="hideModal">Отмена</b-button>
        <b-button class="mt-3 mr-3" variant="danger" @click="removeSection"
          >Удалить</b-button
        >
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
const ItemsSidebar = () => import("components/Common/ItemsSidebar");
const ItemsSidebarItem = () => import("components/Common/ItemsSidebarItem");
const TreeSelector = () => import("components/Common/TreeSelector");
import { getPageSlug } from "utils/helpers";
import {pagination} from "mixins";
import {PAGES_FILTER_ITEMS} from "utils/constants";

export default {
  components: {
    ItemsSidebar,
    ItemsSidebarItem,
    TreeSelector,
  },
	mixins: [pagination],
  props: {
    isEdit: {
      required: false,
      type: Boolean,
    },
    section: {
      required: true,
      type: Object,
    }
  },
  data: () => {
    return {
      selectedPages: [],
			pages: [],
      currentNode: { id: null },
      buttonDisabled: false,
      routeParams: {},
      pageSearch: {
				text: null,
				field: null
			},
			pageFilter: {
				value: null,
				searchType: null
			},
			pagesFilterFields: PAGES_FILTER_ITEMS
    };
  },
  computed: {
    ...mapGetters(["pagesList", "activeSection"]),
    selectedPageIds() {
      return this.selectedPages?.map((el) => el.id) || [];
    },
    tree() {
      return this.activeSection?.tree || [];
    },
    sectionTitle() {
      return this.activeSection?.name_ru || "";
    },
    pageChildrenOptions() {
      const currentChildren = this.$refs["tree_selector"].getPageChildren(
        this.currentNode.id
      );
      let children = this.selectedPages.map((el) => el.id);
      console.log({ currentChildren });
      if (Array.isArray(currentChildren)) {
        children = Array.from(new Set([...currentChildren, ...children]));
      }
      console.log({
        children,
      });
      return {
        children,
        parentId: this.currentNode.id,
      };
    },
  },
	mounted () {
		this.getPages();
	},
  methods: {
    ...mapActions([
      "setPageChildren",
      "removePageFromSection",
      "deleteSection",
      "removeSectionPages",
			"getPagesList"
    ]),
		getPageSlug,
		async getPages() {
			console.log("get pages");
			if (this.allItemsLoaded) {
				return;
			}
			this.$store.commit("SET_LOADING", true);
			try {
				const pages = await this.load(this.getPagesList, {
					page: this.pagination.page,
					type: this.pageFilter?.value,
					searchType: this.pageFilter?.searchType,
					searchText: this.pageSearch.text,
					searchField: this.pageSearch.field,
				});
				if (this.pageSearch) {
					this.allItemsLoaded = false;
				}
				// await this.$store.dispatch('getPagesList', ['custom', 'special'])
				this.$set(this, "pages", [...this.pages, ...pages]);
			} catch (error) {
				console.error(error);
				this.$notify({
					type: "error",
					group: "messages",
					title: "Ошибка",
					text: "Не удалось загрузить страницы!",
				});
			} finally {
				this.$store.commit("SET_LOADING", false);
			}
		},
    onSearch(val) {
			this.reset()
      this.pageSearch = val;
			this.getPages()
    },
		onFilter (filter) {
			this.reset()
			this.pageFilter = filter;
			this.getPages()
		},
		reset () {
			this.pages = [];
			this.pagination.page = 1;
			this.pagination.offset = 0;
			this.allItemsLoaded = false;
		},
    setCurrentNode(node) {
      this.$set(this, "currentNode", node || { id: null });
    },
    selectPage(item) {
      if (this.selectedPageIds.includes(item.id)) {
        this.$set(
          this,
          "selectedPages",
          this.selectedPages.filter((el) => el.id !== item.id)
        );
      } else {
        this.selectedPages.push(item);
      }
    },
    async removePages(pages) {
      await this.removeSectionPages({
        sectionId: +this.$route.params.id,
        pages,
      });
      this.$emit("section:update");
    },
    async removeNode(node) {
      try {
        await this.removePageFromSection({
          id: node.id,
          sectionId: +this.$route.params.id,
        });
        this.$set(this, "currentNode", { id: null });
      } catch (error) {
        console.error(error);
      }
    },

    async setSectionChildren({ parentId, children }) {
      await this.setPages({ parentId, children });
      this.$emit("section:update");
      this.$set(this, "selectedPages", []);
      this.$set(this, "currentNode", { id: null });
    },

    async setPages({ parentId, children }) {
      try {
        if (this.isEdit) {
          let sectionId = +this.$route.params.id;
          await this.setPageChildren({
            children: Array.isArray(children) ? children : [children],
            parentId,
            sectionId,
          });
        } else {
          this.$set(this, "routeParams", {
            parentId: this.currentNode.id,
            children: this.selectedPages.map((el) => el.id),
          });
          await this.setSectionOptions();
        }
      } catch (exception) {
        console.error(exception.response);
				this.$notify({
					type: "error",
					group: "messages",
					title: "Ошибка",
					text: exception.response?.data?.error || "Не удалось изменить порядок страниц!",
				});
      }
    },
    addPages() {},
    openRemoveSectionModal() {
      this.$refs["removeSectionModal"].show();
    },
    hideModal() {
      this.$refs["removeSectionModal"].hide();
    },
    validateForm() {
      return {
        name_ru: !Boolean(this.section?.name_ru.length),
        name_en: !Boolean(this.section?.name_en.length),
      };
    },
    async removeSection() {
      try {
        await this.deleteSection(this.section.id);
        this.$notify({
          group: "messages",
          type: "success",
          title: "Успех",
          text: "Секция удалена",
        });
        await this.$router.push("/sections");
        this.$emit("sections:refresh");
      } catch (error) {
        console.error(error);
        this.$notify({
          group: "messages",
          type: "error",
          title: "Ошибка",
          text: "Не удалось удалить секцию",
        });
        this.hideModal();
      }
    },
    async setSectionOptions() {
      this.$store.commit("SET_LOADING", true);
      console.log("save options");
      this.buttonDisabled = true;
      const errors = this.validateForm();
      console.log("validation", errors);
      if (!Object.values(errors).includes(true)) {
        try {
          if (this.isEdit) {
            await this.$store.dispatch("updateSection", this.section);
            this.$notify({
              group: "messages",
              type: "success",
              title: "Успех",
              text: "Секция обновлена",
            });
          } else {
            const { id } = await this.$store.dispatch(
              "createSection",
              this.section
            );
            this.$notify({
              group: "messages",
              type: "success",
              title: "Успех",
              text: "Страница создана",
            });
            await this.$router.push({
              path: `/sections/${id}`,
              query: this.routeParams,
            });
          }
        } catch (error) {
          console.log("request error");
          console.error(error);
          this.$notify({
            group: "messages",
            type: "error",
            title: "Ошибка",
            text: `Не удалось ${this.isEdit ? "изменить" : "создать"} секцию`,
          });
        }
      } else {
        this.$set(this, "errors", errors);
        console.log("validation fail");
        this.$notify({
          group: "messages",
          type: "error",
          title: "Ошибка",
          text: `Неверно заполнены поля`,
        });
      }
      this.buttonDisabled = false;
      this.$store.commit("SET_LOADING", false);

    },
  },
};
</script>

<style lang="scss" scoped>
.section-content {
  display: grid;
  grid-template-columns: minmax(400px, 1fr) minmax(400px, 1fr) 1fr;
  grid-gap: 30px;
}
</style>
