export default class PageTitleButton {
	constructor (editor) {
		this.editor = editor
	}

	createHTML () {
		return `<div>{{ PAGE_TITLE }}</div>`
	}

	onAction () {
		this.editor.insertContent(this.createHTML(), {})
	}

	create () {
		const button = this
		this.editor.ui.registry.addButton('PageTitleButton', {
			icon: 'format',
			tooltip: 'Заголовок страницы',
			onAction: button.onAction.bind(button)
		})
	}
}
