import Api from 'api'
const api = new Api()

export const state = () => ({

})

export const getters = {

}

export const actions = {
    getEcoplastContacts () {
        return new Promise(async (resolve, reject) => {
            try {
                const {data} = await api.get('contacts')
                resolve(data)
            } catch (error) {
                console.log(error)
                reject(error)
            }
        })
    },
    saveEcoplastContacts (store, contacts) {
        return new Promise(async (resolve, reject) => {
            try {
                const {data} = await api.put('contacts', contacts)
                resolve(data)
            } catch (error) {
                console.log(error)
                reject(error)
            }
        })
    }
}

export const contactsModule = {
    state,
    getters,
    actions
}
