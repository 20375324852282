<template>
    <div class="question-answer">
        <div class="contact-info">
            <b-form-group
                id="question-info-label"
                label="Персональные данные"
                label-for="question-info"
            >
                <b-form-input id="question-info" :value="question.personal_data" trim disabled></b-form-input>
            </b-form-group>
            <b-form-group
                id="question-phone-label"
                label="Телефон"
                label-for="question-phone"
            >
                <b-form-input id="question-phone" :value="question.phone" trim disabled></b-form-input>
            </b-form-group>
            <b-form-group
                id="question-email-label"
                label="Email"
                label-for="question-email"
            >
                <b-form-input id="question-email" :value="question.email" trim disabled></b-form-input>
            </b-form-group>
            <b-form-group
                id="question-created_at-label"
                label="Вопрос задан"
                label-for="question-created_at"
            >
                <b-form-input id="question-created_at" :value="parseDateTime(question.created_at, true)" trim disabled></b-form-input>
            </b-form-group>
            <b-form-group
                v-for="item in questionCompanyFields" :key="item.field"
                :id="item.field"
                :label="item.text"
                :label-for="item.field"
            >
                <b-form-input :id="item.field" :value="question[item.field]" trim disabled></b-form-input>
            </b-form-group>
            <b-form-group
                id="question-locale-label"
                label="Язык вопроса"
                label-for="question-locale"
            >
                <b-form-select id="question-locale" :value="question.locale"
                               :options="locales"
                               :disabled="!question.id"
                               @change="(value) => $emit('question:change', { value, key: 'locale' })"></b-form-select>
            </b-form-group>
        </div>
        <b-form-group
            id="question-text-label"
            label="Вопрос"
            label-for="question-text"
        >
            <b-form-textarea id="question-text" rows="4" max-rows="7" :value="question.text" trim disabled></b-form-textarea>
        </b-form-group>
        <b-form-group
            id="answer-text-label"
            label="Текст ответа"
            label-for="answer-text"
        >
            <b-form-textarea :disabled="!question.id" id="answer-text" rows="4" max-rows="7" :value="answerValue.text" trim @change="(value) => $emit('answer:change', { value, key: 'text' })"></b-form-textarea>
        </b-form-group>
        <div class="d-flex align-items-center">
            <b-form-checkbox id="question-published" :disabled="!question.id" :checked="question.published" @change="(value) => $emit('question:change', { value, key: 'published' })">Опубликован</b-form-checkbox>
            <b-button class="ml-2" variant="success" :disabled="!question.id" @click="$emit('submit')">Сохранить</b-button>
            <b-button class="ml-2" variant="danger" :disabled="!question.id" @click="$emit('delete')">Удалить</b-button>
        </div>
    </div>
</template>

<script>
import {parseDateTime} from 'utils/helpers'
import {LOCALES} from 'utils/constants'
import {QUESTION_COMPANY_INFO_FIELDS} from "../utils/constants";
export default {
    data: () => {
      return {
          locales: LOCALES,
          questionCompanyFields: QUESTION_COMPANY_INFO_FIELDS
      }
    },
    computed: {
        answerValue () {
            return this.answer || {
                text: ''
            }
        }
    },
    props: {
        question: {
            required: false,
            type: Object
        },
        answer: {
            required: false,
            type: Object
        }
    },
    methods: {
        parseDateTime
    }
}
</script>

<style lang="scss" scoped>
.question-answer {
    .contact-info {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
        grid-gap: 20px;
    }
}
</style>
