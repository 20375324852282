const dayjs = require('dayjs')
import config from "./config";

export const documentUploadHandler = async (blobInfo, success, failure) => {
	try {
		const formData = new FormData();
		formData.append('file', blobInfo.blob());
		const url = await loadDocument(formData, 'images')
		success(url)
	} catch (error) {
		console.log(error.response)
		failure(error.response.data.message)
	}
}

export function getPageSlug(page) {
	return page.slug_ru || page.slug_en || page.id
}

export function parseDateTime(value, isUtc) {
	if (value) {
		// let str = value.slice(0, value.indexOf('.000000Z')).replaceAll('-', '/').replaceAll('.', '/').replaceAll(',', '')
		// str = isUtc ? str + ' UTC' : str
		// console.log(str)
		const date = dayjs(value)
		return date.format('DD-MM-YYYY HH:mm:ss')
	} else {
		return '-'
	}
}

export const loadDocument = async (formData, path) => {
	const result = await axios.post(`/api/documents?path=${path}`, formData, {
		headers: {
			'Content-Type': 'multipart/form-data',
			'Authorization': `Bearer ${localStorage.getItem('access_token')}`
		}
	})
	return `${config.app.url}/storage/` + result.data
}

export const deleteDocument = async (path) => {
	return new Promise((resolve, reject) => {
		try {
			resolve(axios.delete(`/api/documents?path=${path}`), {}, {
				headers: {
					'Authorization': `Bearer ${localStorage.getItem('access_token')}`
				}
			})
		} catch (error) {
			reject(error)
		}
	})
}

export const getState = (field, errors) => {
	return errors[field] ? false : null
}

export async function asyncForEach(array, callback) {
	for (let i = 0; i < array.length; i++) {
		await callback(array[i], i, array)
	}
}

export function hasSpecialTag(page, tag) {
	console.log(config)
	if (config[tag]?.id) {
		return config[tag].id == page.tag_id
	} else {
		return false
	}
}

export function cloneObject (object) {
	return JSON.parse(JSON.stringify(object))
}

export default {
	documentUploadHandler,
	getPageSlug,
	parseDateTime,
	loadDocument,
	getState,
	asyncForEach,
	hasSpecialTag
}
