<template>
  <div class="sections">
    <ItemsSidebar
      :items="sectionsList"
			:has-search="false"
      empty-list-text="Секции отсутствуют"
      title="Секции"
    >
      <template v-slot:default="{ item }">
        <ItemsSidebarItem
          :is-active="isActive(item.id)"
          :item="item"
          @click="selectSection(item.id)"
        >
          <template #text>
            <span class="text-overflow">{{
              item.name_ru || item.name_en
            }}</span>
          </template>
					<template #appender>
							<span class="slug ml-auto" :title="`/${item.id}`">
								<router-link :to="`/sections/${item.id}`">
									{{ `/${item.id}` }}
								</router-link>
							</span>
					</template>
        </ItemsSidebarItem>
      </template>
    </ItemsSidebar>
    <div class="section-data">
      <SectionSettings
        :section="activeSection"
        :is-edit="isEdit"
        @input="setSectionValue"
      ></SectionSettings>
      <SectionContent
        class="mt-2"
        :is-edit="Boolean($route.params.id)"
        :section="activeSection"
        ref="content"
        @sections:refresh="getSections"
        @section:update="setActiveSection"
      ></SectionContent>
    </div>
  </div>
</template>

<script>
const ItemsSidebar = () => import("components/Common/ItemsSidebar");
const ItemsSidebarItem = () => import("components/Common/ItemsSidebarItem");
const SectionSettings = () => import("components/Sections/SectionSettings");
import SectionContent from "components/Sections/SectionContent";
import { DEFAULT_SECTION } from "utils/constants";
import { mapGetters, mapMutations, mapActions } from "vuex";
import { pagination } from "mixins";

export default {
  components: {
    ItemsSidebar,
    ItemsSidebarItem,
    SectionSettings,
    SectionContent,
  },
  mounted() {
    this.getSections();
    this.setActiveSection();
  },

  computed: {
    ...mapGetters(["sectionsList", "activeSection"]),
    isEdit() {
      return !isNaN(parseInt(this.$route.params.id));
    },
  },

  watch: {
    "$route.params.id"(id) {
      this.$store.commit("SET_LOADING", true);
      if (this.isEdit) {
        if (!this.$route.query.hasOwnProperty("children")) {
          this.setActiveSection();
        }
      } else {
        this.SET_ACTIVE_SECTION({ id: null, ...DEFAULT_SECTION });
      }
			this.$store.commit("SET_LOADING", false);
    },
    section: {
      deep: true,
      handler: function () {
        this.$store.commit("SET_LOADING", false);
      },
    },
  },

  methods: {
    ...mapMutations(["SET_ACTIVE_SECTION", "SET_SECTION_VALUE"]),
    ...mapActions({
      _getPagesList: "getPagesList",
    }),
    async getSections() {
      this.$store.commit("SET_LOADING", true);
      try {
        await this.$store.dispatch("getSectionsList");
      } catch (error) {
        console.error(error);
        this.$notify({
          type: "error",
          group: "messages",
          title: "Ошибка",
          text: "Не удалось загрузить секции!",
        });
      } finally {
        this.$store.commit("SET_LOADING", false);
      }
    },
    async setActiveSection() {
      try {
        this.$store.commit("SET_LOADING", true);
        if (this.isEdit) {
          console.log(this.$route.query.children);
          if (this.$route.query.hasOwnProperty("children")) {
            console.log(this.$refs["content"]);
            await this.$refs["content"]?.addPages({
              children: this.$route.query.children,
              parentId: this.$route.query.parentId,
            });
          }
          await this.$store.dispatch("getSectionById", this.$route.params.id);
          this.$store.commit("SET_LOADING", false);
        } else {
          this.SET_ACTIVE_SECTION(DEFAULT_SECTION);
        }
        this.$store.commit("SET_LOADING", false);
      } catch (error) {
        console.error(error);
        this.$notify({
          type: "error",
          group: "messages",
          title: "Ошибка",
          text: "Не удалось загрузить секцию!",
        });
        this.SET_ACTIVE_SECTION(DEFAULT_SECTION);
        await this.$router.push("/sections");
      }
    },
    setSectionValue({ key, value }) {
      this.SET_SECTION_VALUE({ key, value });
    },
    resetNode() {
      this.$set(this, "currentNode", { id: null });
    },
    isActive(id) {
      return +this.activeSection.id === id;
    },
    selectSection(id) {
      this.$router.push(`/sections/${id}`);
    },
  },
};
</script>

<style lang="scss" scoped>
.sections {
  display: grid;
  grid-template-columns: minmax(300px, 1fr) 6fr;
  grid-gap: 50px;
  padding: 30px;
}
</style>
