<template>
	<b-modal ref="modalNotifications" size="lg" hide-footer :body-class="'notifications-body'">
		<template #modal-title>
			Уведомления
			<b-button class="ml-3" variant="primary" @click="scheduleRestart" :disabled="!isRestartButtonActive">Запланировать сборку</b-button>
		</template>
		<b-table :items="notifications" :fields="notificationFields" class="table-notification">
			<template #cell(is_active)="{ item }">
				<b-spinner small variant="primary" label="Spinning" v-if="item.is_active"></b-spinner>
			</template>
			<template #cell(command)="{ item }">
				<code>{{ item.command }}</code>
			</template>
			<template #cell(created_at)="{ item }">
				{{ `${new Date(item.created_at).toLocaleDateString()} ${new Date(item.created_at).toLocaleTimeString()}` }}
			</template>
			<template #cell(action)="{ item }">
				<span class="action-button bx bx-trash-alt remove-button"
							:class="{ 'remove-button__disabled': item.is_active }"
							@click="removeNotification(item)"></span>
			</template>
		</b-table>
	</b-modal>
</template>

<script>
import {NOTIFICATION_FIELDS} from "utils/constants";
import {mapGetters} from "vuex";
import {RESTART_CONTAINER_NOTIFICATION} from "utils/constants";

export default {
	data () {
		return {
			notificationFields: NOTIFICATION_FIELDS,
			isLoading: false
		}
	},
	computed: {
		...mapGetters([
			'notifications'
		]),
		isRestartButtonActive () {
			return !(this.notifications.some(el => el.is_active) || this.isLoading)
		}
	},
	mounted () {
		this.$store.dispatch('getNotifications')
	},
	methods: {
		show () {
			this.$refs.modalNotifications.show()
		},
		hide () {
			this.$refs.modalNotifications.hide()
		},
		async removeNotification (item) {
			try {
				await this.$api.delete(`/notifications/${item.id}`)
				await this.$store.dispatch('getNotifications')
			} catch (error) {
				console.error(error)
				this.$notify({
					group: 'messages',
					type: 'error',
					title: 'Ошибка',
					text: 'Не удалось удалить уведомление'
				})
			}
		},
		async scheduleRestart () {
			try {
				this.$set(this, 'isLoading', true)
				await this.$api.post('/notifications', RESTART_CONTAINER_NOTIFICATION)
				await this.$store.dispatch('getNotifications')
			} catch (error) {
				console.error(error)
				this.$notify({
					group: 'messages',
					type: 'error',
					title: 'Ошибка',
					text: 'Не удалось запланировать сборку'
				})
			} finally {
				this.isLoading = false
			}
		}
	}
}
</script>

<style lang="scss">
.notifications-body {
	max-height: 80vh;
	overflow: auto;
}
</style>

<style lang="scss" scoped>
@import "resources/sass/variables";
.remove-button {
	cursor: pointer;
	&:hover {
		background: $green-50;
	}

	&__disabled {
		opacity: 0.3;
		pointer-events: none;
	}
}
</style>
