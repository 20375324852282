<template>
  <div>
    <div
      class="title"
      :style="{ gridTemplateRows: hasSearch ? 'auto 1fr' : null }"
    >
      <div>
        <h2 class="mb-0">{{ title }}</h2>
        <div>
          <b-button
            variant="primary"
            @click="onEmit('submit')"
            v-if="box.in || box.out"
          >
            <i class="bx bx-arrow-from-left" v-if="box.out"></i>
            <i class="bx bx-arrow-from-right" v-if="box.in"></i>
          </b-button>
        </div>
      </div>
			<VFilter
				v-if="isFilterShown"
				:filter-fields="filterFields"
				:value="filter"
				@filter="(val) => onEmit('filter', val)">
			</VFilter>
			<VSearch v-if="hasSearch" :search-fields="searchFields" :value="search" @search="(val) => onEmit('search', val, 500)"
			></VSearch>
    </div>
    <div class="items-wrapper">
      <div
        class="items-list"
        @scroll="onScroll"
        v-if="items.length > 0"
      >
				<transition-group name="slide-fade">
					<div v-for="(item, index) in items" class="item" :key="item.id">
						<slot v-bind:item="{ ...item, index }"></slot>
					</div>
				</transition-group>
      </div>
      <div class="items-list" v-if="items.length === 0">
        <span>{{ emptyListText }}</span>
      </div>
    </div>
  </div>
</template>
<script>
import Loader from "./Loader";
import { VFilter, VSearch } from 'components/Common'
import { SEARCH_FIELDS } from "utils/constants"
export default {
  components: {
    Loader,
		VSearch,
		VFilter
  },
  props: {
		hasSearch: {
			required: false,
			type: Boolean,
			default: true
		},
    search: {
      required: false,
      type: Object
    },
		filter: {
			required: false,
			type: String
		},
		filterFields: {
			required: false,
			type: Array
		},
    orderChangeable: {
      required: false,
      type: Boolean,
    },
    items: {
      required: false,
      type: Array,
			default: () => ([])
    },
    emptyListText: {
      required: false,
      type: String,
      default: "Список пуст",
    },
    title: {
      required: true,
      type: String,
    },
    addButtonTitle: {
      required: false,
      type: String,
      default: "Добавить",
    },
    interactive: {
      required: false,
      type: Boolean,
    },
    itemTextFields: {
      required: false,
      type: Array,
      default: () => {
        return ["name_ru", "name_en"];
      },
    },
    box: {
      required: false,
      type: Object,
      default: () => {
        return {
          in: false,
          out: false,
        };
      },
    },
    preventDefaultSearch: {
      required: false,
      type: Boolean,
    },
  },
  computed: {
		isFilterShown () {
			return Boolean(this.filterFields?.length)
		}
  },
  methods: {
		onEmit (event, filter, timeout = 0) {
			const handle = () => {
				this.$emit(event, filter)
			}
			if (timeout) {
				clearTimeout(this.emitTimeout)
				this.emitTimeout = setTimeout(handle, timeout)
			} else {
				handle()
			}
		},
    emitSearch() {
      this.$emit("search", this.search);
    },
    onScroll(event) {
      const params = {
        height: event.target.scrollHeight - event.target.clientHeight,
        scroll: event.target.scrollTop,
      };
      if (params.height - params.scroll < 50) {
        if (!this.scrollEmited) {
          this.scrollEmited = true;
          this.$emit("scrollend", event);
        }
      } else {
        this.scrollEmited = false;
      }
    },
  },
  data: () => {
    return {
      scrollEmited: false,
			searchFields: SEARCH_FIELDS,
			emitTimeout: null
    };
  },
};
</script>

<style lang="scss" scoped>
@import "resources/sass/variables";

.title {
  display: grid;
  padding: 10px;
  background: white;
  box-shadow: $box-shadow;
  margin-bottom: 5px;
  grid-gap: 5px;
  div {
    display: flex;
    justify-content: space-between;
  }
}

.items-wrapper {
  box-shadow: $box-shadow;
  .items-list {
    background: white;
    max-height: calc(100vh - 200px);
    overflow: auto;
    padding: 10px;
  }
}
</style>
