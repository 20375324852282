var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{ref:"modalNotifications",attrs:{"size":"lg","hide-footer":"","body-class":'notifications-body'},scopedSlots:_vm._u([{key:"modal-title",fn:function(){return [_vm._v("\n\t\tУведомления\n\t\t"),_c('b-button',{staticClass:"ml-3",attrs:{"variant":"primary","disabled":!_vm.isRestartButtonActive},on:{"click":_vm.scheduleRestart}},[_vm._v("Запланировать сборку")])]},proxy:true}])},[_vm._v(" "),_c('b-table',{staticClass:"table-notification",attrs:{"items":_vm.notifications,"fields":_vm.notificationFields},scopedSlots:_vm._u([{key:"cell(is_active)",fn:function(ref){
var item = ref.item;
return [(item.is_active)?_c('b-spinner',{attrs:{"small":"","variant":"primary","label":"Spinning"}}):_vm._e()]}},{key:"cell(command)",fn:function(ref){
var item = ref.item;
return [_c('code',[_vm._v(_vm._s(item.command))])]}},{key:"cell(created_at)",fn:function(ref){
var item = ref.item;
return [_vm._v("\n\t\t\t"+_vm._s(((new Date(item.created_at).toLocaleDateString()) + " " + (new Date(item.created_at).toLocaleTimeString())))+"\n\t\t")]}},{key:"cell(action)",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"action-button bx bx-trash-alt remove-button",class:{ 'remove-button__disabled': item.is_active },on:{"click":function($event){return _vm.removeNotification(item)}}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }