import SpoilerDialog from "./SpoilerDialog";

export default class SpoilerMenuButton {
	constructor (editor) {
		this.editor = editor
		this.spoiler = new SpoilerDialog(editor)
	}

	onAction () {
		this.spoiler.open()
	}

	create () {
		const button = this
		this.editor.ui.registry.addButton('SpoilerMenuButton', {
			icon: 'embed-page',
			tooltip: 'Спойлер',
			onAction: button.onAction.bind(button)
		})
	}
}
