export default {
	data: () => {
		return {
			lastModified: {
				default: null
			}
		}
	},
	computed: {
		lastModifiedStyle () {
			return  `
			border: 1px #1d68a7 dashed;
			box-sizing: content-box;`
		}
	},
	methods: {
		modificationStyle (section = 'default', id) {
			return this.isLastModified(section, id) ? this.lastModifiedStyle : ''
		},
		isLastModified(section = 'default', id) {
			return this.lastModified[section] === id
		},
		setLastModified (section = 'default', id) {
			this.$set(this.lastModified, section, id)
		}
	},
	beforeRouteLeave() {
		this.lastModified = {
			default: null
		}
	},
}
