import Vue from "vue";
import Api from "api"
const api = new Api();

export const state = () => ({
    isLoading: false
})

export const getters = {
    isLoading: state => state.isLoading
}

export const mutations = {
    SET_LOADING (state, isLoading) {
        Vue.set(state, 'isLoading', isLoading)
    }
}

export const actions = {
    updateMainScript (store, script) {
        return new Promise(async (resolve, reject) => {
            try {
                console.log(script)
                await api.put('/main-script', { script })
                resolve()
            } catch (error) {
                reject(error)
            }
        })
    },
    getMainScript (store) {
        return new Promise (async (resolve, reject) => {
            try {
                const { data } = await api.get('/main-script')
                resolve(data)
            } catch (error) {
                reject(error)
            }
        })
    }
}

export const commonModule = {
    state,
    mutations,
    getters,
    actions
}

