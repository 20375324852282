<template>
    <div>
        <Loader v-show="isLoading"></Loader>
        <div v-if="$route.meta.hasHeader">
            <Header />
        </div>
        <div>
            <router-view></router-view>
        </div>
        <notifications group="messages" />
    </div>
</template>
<script>
import Header from "./components/Header";
import Loader from "./components/Common/Loader";
import {mapGetters} from "vuex";

export default {
    mounted () {
        this.$nextTick(() => {
            this.$set(this, 'loaderHeight', document.body.offsetHeight + 'px')
        })
    },
    data: () => {
      return {
          loaderHeight: '100vh'
      }
    },
    components: {
        Header,
        Loader
    },
    computed: {
        ...mapGetters([
            'isLoading'
        ])
    }
}
</script>
<style lang="scss">
.b-form-datepicker .form-control{
	border: 0!important;
    background: 0!important;
}
.b-calendar-grid-body .btn{
	font-size: 12px!important;
    padding: 0.25rem 0.65rem!important;
}
</style>
