import SpoilerMenuButton from "./tinyMCE/spoiler/SpoilerMenuButton";
import PageTitleButton from "./tinyMCE/PageTitleButton";

export const AUTH_LOGIN_FIELDS = [
	{
		id: 'email',
		text: 'Email',
		field: 'email',
		type: 'text'
	},
	{
		id: 'password',
		text: 'Пароль',
		field: 'password',
		type: 'password'
	}
]


export const AUTH_LOGIN_BUTTONS = [
	{
		id: 'login',
		text: 'Войти',
		variant: 'success',
		event: 'auth:login'
	}
]

export const SECTION_TYPE_DROPDOWN_ITEMS = [
	{
		text: 'Боковое меню',
		value: 'sidebar'
	},
	{
		text: 'Шапка сайта',
		value: 'header'
	},
	{
		text: 'Подвал сайта',
		value: 'footer'
	},
	{
		text: 'Без назначения',
		value: null
	}
]

export const SECTION_SETTINGS = [
	{
		text: 'RU',
		placeholder: 'Русское название',
		field: 'name_ru'
	},
	{
		text: 'EN',
		placeholder: 'Английское название',
		field: 'name_en'
	},
	{
		text: 'Положение',
		placeholder: 'Выберете положение секции',
		field: 'type',
		type: 'select',
		options: SECTION_TYPE_DROPDOWN_ITEMS
	},
	{
		text: 'Позиция',
		placeholder: 'Выберете позицию секции',
		field: 'order',
		type: 'number'
	},
]

export const PAGE_NAMING_FIELDS = [
    {
        text: 'RU | Название',
        placeholder: 'Русское название',
        field: 'name_ru'
    },
    {
        text: 'EN | Название',
        placeholder: 'Английское название',
        field: 'name_en'
    },
    {
        text: 'RU | Название ссылки',
        placeholder: 'Русское название ссылки',
        field: 'name_alt_ru'
    },
    {
        text: 'EN | Название ссылки',
        placeholder: 'Английское название ссылки',
        field: 'name_alt_en'
    },
    {
        text: 'RU | Описание',
        placeholder: 'Описание страницы на русском языке',
        field: 'description_ru'
    },
    {
        text: 'EN | Описание',
        placeholder: 'Описание страницы на английском языке',
        field: 'description_en'
    },
		{
			text: 'RU | slug',
			placeholder: 'Значение в URL для русской версии',
			field: 'slug_ru'
		},
		{
			text: 'EN | slug',
			placeholder: 'Значение в URL для английской версии',
			field: 'slug_en'
		}
]

export const PAGE_SEO_FIELDS = [
		{
			text: 'RU | title',
			placeholder: 'seo_title',
			field: 'seo_title_ru'
		},
		{
			text: 'RU | desc',
			placeholder: 'seo_description',
			field: 'seo_description_ru'
		},
		{
			text: 'RU | kword',
			placeholder: 'seo_keywords',
			field: 'seo_keywords_ru'
		},
		{
			text: 'EN | title',
			placeholder: 'seo_title',
			field: 'seo_title_en'
		},
		{
			text: 'EN | desc',
			placeholder: 'seo_description',
			field: 'seo_description_en'
		},
		{
			text: 'EN | kword',
			placeholder: 'seo_keywords',
			field: 'seo_keywords_en'
		}
	]

export const SEARCH_FIELDS = [
	{
		value: 'name_ru',
		text: 'Русское название'
	},
	{
		value: 'name_en',
		text: 'Английское название'
	},
	{
		value: 'name_alt_ru',
		text: 'Русское название ссылки'
	},
	{
		value: 'name_alt_en',
		text: 'Английское название ссылки'
	},
	{
		value: 'slug_ru',
		text: 'Русский фрагмент URL'
	},
	{
		value: 'slug_en',
		text: 'Английский фрагмент URL'
	},
	{
		value: 'id',
		text: 'Идентификатор'
	}
]

export const DEFAULT_SECTION = {
	name_ru: 'Новая секция',
	name_en: 'New section',
	order: 0,
	type: 'sidebar'
}

export const DEFAULT_SLIDER = {
	name: 'Новый слайдер',
	rotation_type: 'linear',
	rotation_delay: 5000
}

export const SLIDER_ROTATION_TYPE_DROPDOWN_ITEMS = [
	{
		text: 'slide',
		value: 'slide'
	},
	{
		text: 'fade',
		value: 'fade'
	},
	{
		text: 'cube',
		value: 'cube'
	},
	{
		text: 'coverflow',
		value: 'coverflow'
	},
	{
		text: 'flip',
		value: 'flip'
	},
	{
		text: 'creative',
		value: 'creative'
	},
	{
		text: 'cards',
		value: 'cards'
	}
]

export const SLIDER_SETTINGS = [
	{
		text: 'Название',
		placeholder: 'Название',
		field: 'name'
	},
	{
		text: 'Эффект прокрутки',
		placeholder: 'Выберете эффект слайдов',
		field: 'rotation_type',
		type: 'select',
		options: SLIDER_ROTATION_TYPE_DROPDOWN_ITEMS
	},
	{
		text: 'Задержка',
		placeholder: 'Время между слайдами',
		field: 'rotation_delay',
		type: 'number'
	},
]


export const DEFAULT_PAGE = {
	parent_id: null,
	name_ru: "",
	name_en: "",
	order: null,
	html_ru: "",
	html_en: "",
	name_alt_ru: null,
	name_alt_en: null,
	type: 'custom',
	seo_title_ru: "",
	seo_title_en: "",
	seo_description_ru: "",
	seo_description_en: "",
	seo_keywords_ru: "",
	seo_keywords_en: "",
	meta_tags_ru: [],
	meta_tags_en: [],
	links: [],
	links_title_ru: '',
	links_title_en: '',
	links_description_ru: '',
	links_description_en: '',
	description_ru: '',
	description_en: '',
	links_files: [],
	banner_url: null,
	slug_ru: null,
	slug_en: null,
	url: {
		ru: '',
		en: ''
	},
	redirects: [],
	created_at: (new Date()).toISOString()
}

export const PAGES_FILTER_ITEMS = [
	{
		text: 'Обычная',
		value: 'custom',
		searchType: 'type'
	},
	{
		text: 'Специальная',
		value: 'special',
		searchType: 'type'
	},
	{
		text: 'Новость',
		value: 'news_item',
		searchType: 'tag'
	}
]

export const PAGE_TYPES = [
	{
		text: 'Обычная',
		value: 'custom'
	}, {
		text: 'Специальная',
		value: 'special'
	}
]

export const SECTIONS = [
	{
		type: 'news',
		name: 'Новости'
	},
	{
		type: 'sidebar',
		name: 'Боковое меню'
	},
	{
		type: 'header',
		name: 'Шапка сайта'
	},
	{
		type: 'footer',
		name: 'Подвал сайта'
	}
]

export const CONTACTS_FIELDS = [
	{
		field: 'address',
		label: 'Адрес организации',
		type: 'text'
	},
	{
		field: 'travel',
		label: 'Проезд',
		type: 'textarea'
	},
	{
		field: 'phone_number',
		label: 'Номер телефона',
		type: 'tel'
	},
	{
		field: 'email',
		label: 'Электронная почта',
		type: 'email'
	}
]

export const LOCALES = {'ru': 'Русский', 'en': 'Английский'}

export const TAGS_LOCALES = {
	// main: 'Главная страница',
	// news: 'Новости',
	// catalog: 'Каталог',
	// support: 'Поддержка',
	// faq: 'FAQ',
	// 'price-lists': 'Прайс листы',
	// catalogs: 'Каталоги',
	// certificates: 'Сертификаты',
	// vacancies: 'Вакансии',
	// stands: 'Стенды',
	// 'technical-info': 'Техническая информация',
	// standards: 'Стандарты',
	// articles: 'Статьи'
}


export const DEFAULT_LINK_FILE = {
	name: 'file',
	link: null,
	document: null
}

export const DEFAULT_SLIDE = {
	order: 0,
	slider_id: 0,
	link: null,
	file_url: null,
	mobile_file_url: null,
}
export const QUESTION_COMPANY_INFO_FIELDS = [
	{
		field: 'person_position',
		text: 'Должность'
	},
	{
		field: 'company_name',
		text: 'Компания'
	},
	{
		field: 'company_address',
		text: 'Адрес компании'
	},
	{
		field: 'company_fax',
		text: 'Факс'
	},
	{
		field: 'company_web_site',
		text: 'Веб-сайт'
	},
	{
		field: 'company_scope',
		text: 'Сфера деятельности комании'
	},
	{
		field: 'company_region',
		text: 'Регион работы комании'
	}
]

export const NOTIFICATION_FIELDS = [
	{
		key: 'title',
		label: 'Название',
		sortable: true
	},
	{
		key: 'type',
		label: 'Тип'
	},
	{
		key: 'text',
		label: 'Текст'
	},
	{
		key: 'command',
		label: 'Команда'
	},
	{
		key: 'is_active',
		label: 'Активность'
	},
	{
		key: 'created_at',
		label: 'Дата'
	},
	{
		key: 'action',
		label: ''
	}
]

export const RESTART_CONTAINER_NOTIFICATION = {
	title: 'Сборка сайта',
	type: 'system',
	text: 'Пересборка сайта для обновления путей страниц',
	command: 'container:restart',
	is_active: true
}
