import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import {userModule} from './modules/user'
import {pageModule} from './modules/page'
import {metaTagsModule} from './modules/metaTags'
import {sectionsModule} from './modules/section'
import {slidersModule} from './modules/slider'
import {contactsModule} from './modules/contacts'
import {commonModule} from './modules/common'
import {questionsModule} from './modules/questions'
import {redirectModule} from "./modules/redirect"
import {notificationModule} from './modules/notifications'
import {documentsModule} from './modules/documents'
const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
	strict: debug,
	modules: {
		documentsModule,
		userModule,
		pageModule,
		metaTagsModule,
		sectionsModule,
		contactsModule,
		commonModule,
		questionsModule,
		redirectModule,
		notificationModule,
		slidersModule
	}
})
