import Vue from 'vue'
import Api from 'api'
const api = new Api();

export const state = () => ({
	notifications: []
})

export const getters = {
	notifications: state => state.notifications
}

export const mutations = {
	SET_NOTIFICATIONS (state, notifications) {
		Vue.set(state, 'notifications', notifications)
	}
}

export const actions = {
	getNotifications ({commit}) {
		return new Promise(async (resolve, reject) => {
			try {
				const {data} = await api.get('/notifications')
				commit('SET_NOTIFICATIONS', data)
				resolve(data)
			} catch (error) {
				reject(error)
			}
		})
	}
}

export const notificationModule = {
	state, getters, mutations, actions
}
