<template>
	<b-nav-item @click="$emit('modal:open')">
		<div class="d-flex flex-row align-items-start">
			<h5 class="mb-0">
				События
			</h5>
			<span class="bx bx-info-circle" v-if="activeNotificationsExists" style="color: red"></span>
		</div>
	</b-nav-item>
</template>

<script>
import {mapGetters} from "vuex";

export default {
	computed: {
		...mapGetters([
			'notifications'
		]),
		activeNotificationsExists () {
			return this.notifications.some(el => el.is_active)
		}
	},
}
</script>
