<template>
	<div class="filter" :style="{ gridTemplateColumns: `repeat(${filterFields.length}) 1fr` }">
		<div class="filter-field list-element"
				 :class="{ 'list-element-active': value === item.value }"
				 v-for="item in filterFields" @click="setFilterField(item)">
			{{ item.text }}
		</div>
	</div>
</template>

<script>
export default {
	props: {
		filterFields: {
			required: true,
			type: Array
		},
		value: {
			required: false,
			type: [ String, Number ]
		}
	},
	model: {
		prop: 'value',
		event: 'filter'
	},
	methods: {
		setFilterField (params) {
			if (params?.value === this.value) {
				this.$emit('filter', { value: null })
				return
			}
			this.$emit('filter', params)
		}
	}
}
</script>

<style scoped lang="scss">
@import 'resources/sass/variables';
.filter {
	display: grid;
	background: $body-bg;
	border-radius: 5px;

}
</style>
