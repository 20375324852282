<template>
	<b-navbar class="navbar">
		<b-navbar-brand>
			<img :src="logo" alt="ecoplast" class="logo">
		</b-navbar-brand>
		<b-navbar-nav left>
			<b-nav-item @click="openModal('modalContacts')">
				<h5 class="mb-0">
					Контакты
				</h5>
			</b-nav-item>
			<b-nav-item @click="openModal('modalScript')">
				<h5 class="mb-0">
					Общий скрипт
				</h5>
			</b-nav-item>
			<b-nav-item>
				<router-link to="/pages">
					<h5 class="mb-0" :class="{ 'text-primary': $route.fullPath.includes('/pages') }">
						Страницы
					</h5>
				</router-link>
			</b-nav-item>
			<b-nav-item>
				<router-link to="/sections">
					<h5 class="mb-0" :class="{ 'text-primary': $route.fullPath.includes('/sections') }">
						Секции
					</h5>
				</router-link>
			</b-nav-item>
			<b-nav-item @click="openModal('modalTags')">
				<h5 class="mb-0">
					Теги
				</h5>
			</b-nav-item>
			<b-nav-item @click="$router.push('/questions')">
				<h5 class="mb-0" :class="{ 'text-primary': $route.fullPath.includes('/questions') }">
					Вопросы
				</h5>
			</b-nav-item>
			<b-nav-item @click="$router.push('/sliders')">
				<h5 class="mb-0" :class="{ 'text-primary': $route.fullPath.includes('/sliders') }">
					Слайдеры
				</h5>
			</b-nav-item>
			<NotificationButton @modal:open="openModal('modalNotifications')"></NotificationButton>
		</b-navbar-nav>
		<b-navbar-nav class="ml-auto">
			<b-nav-item>
				<b-button @click="createPage" variant="primary">Создать страницу</b-button>
			</b-nav-item>
			<b-nav-item>
				<b-button @click="createSection" variant="primary">Создать секцию</b-button>
			</b-nav-item>
			<b-nav-item>
				<b-button @click="createSlider" variant="primary">Создать слайдер</b-button>
			</b-nav-item>
			<b-nav-item>
				<b-button @click="logout">Выйти</b-button>
			</b-nav-item>
		</b-navbar-nav>
		<b-modal ref="modalContacts" title="Контакты" @show="getContacts">
			<div class="contacts" v-if="contacts">
				<div v-for="(item, index) in fields">
					<b-form-group
						:id="item.label + '_label'"
						:label="item.label"
						:label-for="item.label">
						<b-form-textarea v-if="item.type==='textarea'" rows="3" max-rows="6" :id="item.label"
														 v-model.lazy="contacts[item.field]" trim></b-form-textarea>
						<b-form-input v-else :id="item.label" :type="item.type" v-model.lazy="contacts[item.field]"
													trim></b-form-input>
					</b-form-group>
				</div>
			</div>
			<template #modal-footer>
				<b-button @click="hide" class="mr-2">Закрыть</b-button>
				<b-button @click="saveContacts" variant="primary">Сохранить</b-button>
			</template>
		</b-modal>
		<b-modal ref="modalTags" title="Теги страниц" @show="getPageTags">
			<div class="page-tags">
				<ItemsSidebar
					class="pages-list"
					:items="pageTags"
					:has-search="false"
					empty-list-text="Теги отсутствуют"
					title="Список тегов"
					:item-text-fields="['name']"
				>
					<template v-slot:default="{ item }">
						<ItemsSidebarItem
							:item="item">
							<template #text>
								<span class="text-overflow"
											v-contenteditable="item.name"
											@edit="(event) => setName(item.id, event.detail.value())">
									{{ item.name }}
								</span>
							</template>
						</ItemsSidebarItem>
					</template>
				</ItemsSidebar>
				<b-input-group class="mt-2">
					<b-form-input v-model="tagValue"></b-form-input>
					<template #append>
						<b-button variant="primary" @click="addPageTag">Добавить</b-button>
					</template>
				</b-input-group>
			</div>
			<template #modal-footer>
				<b-button @click="hide" class="mr-2">Закрыть</b-button>
			</template>
		</b-modal>
		<b-modal ref="modalScript" size="lg" title="Общий скрипт" @show="getScript">
			<prism-editor class="code-editor" :value="mainScript" @input="setMainScript" :highlight="highlighter"
										v-if="currentModal === 'modalScript'" line-numbers></prism-editor>
			<template #modal-footer>
				<b-button @click="hide" class="mr-2">Закрыть</b-button>
				<b-button variant="primary" :disabled="isLoading" @click="saveScript" class="mr-2">Сохранить</b-button>
			</template>
		</b-modal>
		<NotificationModal ref="modalNotifications"></NotificationModal>
	</b-navbar>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import {DEFAULT_PAGE} from "utils/constants";
import logo from 'images/ecoplast.svg'
import {CONTACTS_FIELDS, DEFAULT_SECTION, TAGS_LOCALES, DEFAULT_SLIDER} from "../utils/constants";
import ItemsSidebar from "./Common/ItemsSidebar";
import ItemsSidebarItem from "./Common/ItemsSidebarItem";


// import Prism Editor
const PrismEditor = () => import('vue-prism-editor').then(data => data.PrismEditor)
import 'vue-prism-editor/dist/prismeditor.min.css';
import {highlight, languages} from "prismjs/components/prism-core"; // import the styles somewhere
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-javascript'
import {notifications} from 'mixins'

export default {
	components: {
		ItemsSidebar,
		ItemsSidebarItem,
		PrismEditor
	},
	mixins: [notifications],
	data () {
		return {
			logo,
			fields: CONTACTS_FIELDS,
			contacts: null,
			tagValue: '',
			mainScript: '',
			currentModal: null,
			isLoading: false,
			content: true
		}
	},
	computed: {
		...mapGetters([
			'pageTags'
		]),
		filteredTags() {

		}
	},
	methods: {
		...mapActions([
			'getEcoplastContacts',
			'saveEcoplastContacts',
			'getTags',
			'addTag',
			'updateMainScript',
			'getMainScript',
			'updateTag'
		]),
		...mapMutations([
			'SET_ACTIVE_PAGE',
			'SET_ACTIVE_SECTION',
			'SET_ACTIVE_SLIDER'
		]),
		async setName(id, name) {
			try {
				this.$store.commit('SET_TAG_NAME', {id, name: name.trim()})
				await this.updateTag({id, name: name.trim()})
			} catch (error) {
				console.log(error)
			}
		},
		async saveScript() {
			try {
				this.isLoading = true
				await this.updateMainScript(this.mainScript)
				this.$notify({
					group: 'messages',
					type: 'success',
					title: 'Успех',
					text: 'Общий скрипт успешно обновлен'
				})
			} catch (error) {
				console.error(error)
				this.$notify({
					group: 'messages',
					type: 'error',
					title: 'Ошибка',
					text: 'Не удалось обновить общий скрипт'
				})
			} finally {
				this.isLoading = false
				this.hide()
				this.mainScript = null
			}
		},
		highlighter(code) {
			return highlight(code, languages.js, 'js'); // languages.<insert language> to return html with markup
		},
		setMainScript(script) {
			this.mainScript = script.toString()
		},
		async getScript() {
			try {
				const script = await this.getMainScript()
				this.mainScript = script.toString()
			} catch (error) {
				console.error(error)
				this.$notify({
					group: 'messages',
					type: 'error',
					title: 'Ошибка',
					text: 'Не удалось получить общий скрипт'
				})
			}
		},
		async getPageTags() {
			try {
				const tags = await this.getTags()
				this.$store.commit('SET_TAGS', tags.map(tag => {
					return {
						name: TAGS_LOCALES[tag.name] ? `${tag.name} - ${TAGS_LOCALES[tag.name]}` : tag.name,
						id: tag.id
					}
				}))
			} catch (error) {
				this.$notify({
					group: 'messages',
					type: 'error',
					title: 'Ошибка',
					text: 'Не удалось получить теги страниц'
				})
				this.hide()
			}
		},
		async getContacts() {
			try {
				const contacts = await this.getEcoplastContacts()
				this.$set(this, 'contacts', contacts)
			} catch (error) {
				this.$notify({
					group: 'messages',
					type: 'error',
					title: 'Ошибка',
					text: 'Не удалось получить контакнтые данные'
				})
				this.hide()
			}
		},
		async addPageTag() {
			try {
				await this.addTag(this.tagValue)
				this.getPageTags()
				this.$set(this, 'tagValue', '')
				this.$root.$emit('tags:reload')
			} catch (error) {
				console.error(error)
				this.$notify({
					group: 'messages',
					type: 'error',
					title: 'Ошибка',
					text: 'Не удалось добавить тег'
				})
			}
		},
		hide() {
			this.$refs['modalContacts']?.hide()
			this.$refs['modalTags']?.hide()
			this.$refs['modalScript']?.hide()
			this.currentModal = null
			this.$set(this, 'tagValue', '')
		},
		async saveContacts() {
			try {
				await this.saveEcoplastContacts(this.contacts)
				this.$notify({
					group: 'messages',
					type: 'success',
					title: 'Успех',
					text: 'Контактные данные успешно изменены'
				})
				this.hide()
			} catch (error) {
				console.error(error)
				this.$notify({
					group: 'messages',
					type: 'error',
					title: 'Ошибка',
					text: 'Не удалось обновить контактные данные'
				})
			}
		},
		openModal(modal) {
			this.currentModal = modal
			console.log(this.$refs[modal])
			this.$refs[modal]?.show()
		},
		logout() {
			this.$store.dispatch('logoutUser', this.$router)
		},
		createPage() {
			this.SET_ACTIVE_PAGE(DEFAULT_PAGE)
			this.$router.push('/pages')
		},
		createSection() {
			this.SET_ACTIVE_SECTION(DEFAULT_SECTION)
			this.$router.push('/sections')
		},
		createSlider() {
			this.SET_ACTIVE_SLIDER(DEFAULT_SLIDER)
			this.$router.push('/sliders')
		}
	}
}
</script>

<style lang="scss" scoped>
@import "resources/sass/variables";

.navbar {
	background: white;
	box-shadow: $box-shadow;

	.logo {
		height: 40px;
	}

	.page-tags {
		max-height: calc(100vh - 300px);
		overflow: auto;
	}
}

.code-editor {
	/* we dont use `language-` classes anymore so thats why we need to add background and text color manually */
	background: white;
	color: #333;

	/* you must provide font-family font-size line-height. Example: */
	font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
	font-size: 14px;
	line-height: 1.5;
	padding: 5px;
}

/* optional class for removing the outline */
.prism-editor__textarea:focus {
	outline: none;
}
</style>
