import {documentUploadHandler} from "../helpers";
import SpoilerMenuButton from "./spoiler/SpoilerMenuButton";
import PageTitleButton from "./PageTitleButton";
import LinksButton from "./links/Links";

export default class TinyMCE {
	constructor() {}

	get contentStyle() {
		return `
		.spoiler {
			border: 1px solid #333;
		}
		.spoiler-toggle {
			display: none;
		}
		.spoiler-toggle-label > span {
			border: 1px solid #333;
		}
		.spoiler-content {
			margin: 5px;
		}
		.spoiler-content-shown {
			border: 1px dashed blue;
		}
		.spoiler-content-hidden {
			border: 1px dashed red;
			color: gray;
		}
		.bottom-shadow {
			width: 0px;
			display: none;
		}
	`
	}

	get options() {
		return {      // Object. Настройки редактора tinyMCE
			valid_elements: '+*[*]',
			selector: "textarea",
			forced_root_block: 'div',
			content_style: this.contentStyle,
			plugins: ["autolink lists link image charmap print preview hr pagebreak",
				"searchreplace wordcount visualblocks visualchars code",
				"insertdatetime nonbreaking save table directionality",
				"emoticons paste textpattern code"],
			relative_urls: false,
			remove_script_host: false,
			height: 600,
			convert_urls: true,
			image_prepend_url: "",
			images_upload_handler: documentUploadHandler,
			setup: this.setup.bind(this)
		}
	}

	get toolbar () {
		const components = Object.keys((this.getComponents() || {}))
		const toolbar = components.join(' | ')
		console.log({ toolbar })
		return toolbar
	}

	getComponents (editor ) {
		return {
			SpoilerMenuButton: new SpoilerMenuButton(editor),
			PageTitleButton: new PageTitleButton(editor),
			LinksButton: new LinksButton(editor)
		}
	}

	setup (editor) {
		this.initComponents(editor)
		editor.settings.toolbar = this.toolbar
	}

	initComponents (editor) {
		const components = this.getComponents(editor)
		for (let key of Object.keys(components)) {
			try {
				const component = components[key]
				console.log(`create component ${key}`, component)
				component.create.call(component)
			} catch (error) {
				console.error(`Unable to init component ${key}`)
			}
		}
	}
}
