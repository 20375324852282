export default {
	data: () => {
		return {
			pagination: {
				limit: 50,
				offset: 0,
				page: 1
			},
			allItemsLoaded: false,
		}
	},
	methods: {
		async load(callback, params = {page: 1}) {
			try {
				const options = {
					...params,
					...this.getParams(params.page)
				}
				console.log({ callback, options })
				const data = await callback(options)
				if (data.length < this.pagination.limit) {
					this.allItemsLoaded = true;
				}
				this.pagination.page++;
				return data
			} catch (error) {
				console.error(error)
			}
		},
		getParams(page) {
			return {
				limit: this.pagination.limit,
				offset: this.pagination.limit * (page - 1)
			}
		},
		resetPagination() {
			this.pagination.page = 1;
			this.allItemsLoaded = false;
		}
	}
}
