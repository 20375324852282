import Vue from "vue";
import Api from "api"
const api = new Api();


export const userModule = {
    state: () => ({
        user: {
            email: '',
            username: '',
            isAuth: false
        }
    }),

    getters: {
      getUser: (state) => state.user
    },

    mutations: {
        SET_AUTHENTICATED (state, flag) {
            Vue.set(state.user, 'isAuth', flag)
        },
        SET_USER (state, user) {
            Vue.set(state, 'user', { ...state.user, ...user } )
        }
    },

    actions: {
        loginUser({ commit }, { email, password, router }) {
            return new Promise(async (resolve, reject) => {
                try {
                    const { data } = await api.post('/login', {
                        email, password
                    })
                    localStorage.setItem('access_token', data.token)
                    localStorage.setItem('user', JSON.stringify(data.user))
                    commit('SET_AUTHENTICATED', true)
                    commit('SET_USER', data.user)
                    router.push('/')
                } catch (error) {
                    console.error(error)
                    commit('SET_AUTHENTICATED', false)
                    reject(error.response)
                }
            })
        },
        registerUser({ commit, dispatch }, { email, password, name, router }) {
            return new Promise(async (resolve, reject) => {
                try {
                    const { data } = await api.post('/register', {
                        email, password, name
                    })
                    if (data.id) {
                        resolve(dispatch('loginUser', { email, password, router }))
                    }
                } catch (error) {
                    console.error(error)
                    commit('SET_AUTHENTICATED', false)
                    reject(error.response)
                }
            })
        },
        logoutUser ({ commit }, router) {
            return new Promise(async (resolve, reject) => {
                try {
                    await api.post('/logout')
                    localStorage.removeItem('access_token')
                    localStorage.removeItem('user')
                    commit('SET_AUTHENTICATED', false)
                    commit('SET_USER', {})
                } catch (error) {
                    console.error(error)
                    reject(error.response)
                }
                router.push('/login')
            })
        },
        checkToken ({ commit }) {
            const token = localStorage.getItem('access_token')
            commit('SET_AUTHENTICATED', Boolean(token))
            if (Boolean(token)) {
                commit('SET_USER', JSON.parse(localStorage.getItem('user')))
            }
            console.log('has token ' + Boolean(token))
            return Boolean(token)
        }
    }

}
