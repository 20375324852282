export const contenteditable = {
	bind (el, binding, vnode) {
		let timeout = null
		el.addEventListener('dblclick', function (e) {
			el.contentEditable = true
			el.focus()
		})
		const event = new CustomEvent('edit', { detail: { value: () => el.innerText } })

		el.addEventListener('input', () => {
			clearTimeout(timeout)
			timeout = setTimeout(() => {
				el.dispatchEvent(event)
			}, 2000)
		})
	}
}
