import Vue from "vue";
import Api from "api"
const api = new Api();


export const slidersModule = {
    state: () => ({
        list: [],
        activeSlider: {
            slides: []
        }
    }),

    getters: {
        slidersList: state => state.list,
        activeSlider: state => state.activeSlider
    },

    mutations: {
        SET_SLIDER_LIST (state, list) {
            Vue.set(state, 'list', list)
        },
        SET_ACTIVE_SLIDER (state, section) {
            Vue.set(state, 'activeSlider', section)
        },
        SET_SLIDER_VALUE(state, {field, value}) {
			if (state.activeSlider) {
				Vue.set(state.activeSlider, field, value)
			}
		},
        UPDATE_ACTIVE_SLIDER_SLIDE(state, data) {
			const files = state.activeSlider.slides
			const updatedFiles = files.map(file => {
				if (file.id === data.id) {
					return {
						...file,
						...data
					}
				} else {
					return file
				}
			})
			console.log(updatedFiles)
			Vue.set(state.activeSlider, 'slides', updatedFiles)
		}
    },

    actions: {
        getSlidersList ({ commit }) {
            return new Promise(async (resolve, reject) => {
                try {
                    const {data} = await api.get('/sliders')
                    commit('SET_SLIDER_LIST', data)
                    resolve(data)
                } catch (error) {
                    reject(error)
                }
            })
        },
        getSliderById ({ commit }, id) {
            return new Promise(async (resolve, reject) => {
                try {
                    const {data} = await api.get(`/sliders/${id}`)
                    commit('SET_ACTIVE_SLIDER', data)
                    resolve()
                } catch (error) {
                    reject(error)
                }
            })
        },

        removePageFromSection ({ dispatch }, { id, sectionId }) {
            return new Promise(async (resolve, reject) => {
                try {
                    const { data } = await api.delete(`/sections/${sectionId}/pages/${id}`)
                    resolve(data)
                } catch (error) {
                    reject(error)
                }
            })
        },

        createSlider ({ dispatch }, section) {
            return new Promise(async (resolve, reject) => {
                try {
                    const { data } = await api.post('/sliders', section)
                    await dispatch('getSlidersList')
                    resolve(data)
                } catch (error) {
                    reject(error)
                }
            })
        },

        updateSlider ({ dispatch }, section) {
            return new Promise(async (resolve, reject) => {
                try {
                    const { data } = await api.put(`/sliders/${section.id}`, section)
                    await dispatch('getSlidersList')
                    resolve(data)
                } catch (error) {
                    reject(error)
                }
            })
        },

        deleteSlider(store, id) {
            return new Promise(async (resolve, reject) => {
                try {
                    await api.delete(`/sliders/${id}`)
                    resolve()
                } catch (error) {
                    reject(error)
                }
            })
        },

        createSlide(store, {sliderId, slide}) {
			return new Promise(async (resolve, reject) => {
				try {
					const {data} = await api.post(`/sliders/${sliderId}/slides`, slide)
					resolve(data)
				} catch (error) {
					reject(error)
				}
			})
		},

		deleteSlide(store, {sliderId, id}) {
			return new Promise(async (resolve, reject) => {
				try {
					await api.delete(`/sliders/${sliderId}/slides/${id}`)
					resolve()
				} catch (error) {
					reject(error)
				}
			})
		},

        updateSlide ({ dispatch }, slide) {
            return new Promise(async (resolve, reject) => {
                try {
                    const { data } = await api.put(`/sliders/${slide.slider_id}/slides/${slide.id}`, slide)
                    resolve(data)
                } catch (error) {
                    reject(error)
                }
            })
        },
    }
}
