import LinkRefs from "./LinkRefs";
import LinksFilesBanners from "./LinksFilesBanners";
import LinkBlock from "./LinkBlock";
import LinkDropdown from "./LinkDropdown";
import LinksFilesRefs from "./LinksFilesRefs";


export default class LinksButton {
	constructor (editor) {
		this.editor = editor
	}

	getSubmenuItems (callback) {
		const components = {
			linksRef: new LinkRefs(this.editor),
			linksBanners: new LinksFilesBanners(this.editor),
			linksBlock: new LinkBlock(this.editor),
			linksDropdown: new LinkDropdown(this.editor),
			linksFilesRefs: new LinksFilesRefs(this.editor),
		}
		const items = Object.values(components)
		callback(items.map(item => item.options))
	}

	create () {
		const button = this
		this.editor.ui.registry.addMenuButton('LinksButton', {
			icon: 'link',
			tooltip: 'Ссылки',
			text: 'Ссылки',
			fetch: button.getSubmenuItems.bind(button)
		})
	}
}
