import NotificationButton from "components/Notifications/NotificationButton";
import NotificationModal from 'components/Notifications/NofiticationModal'

export default {
	components: {
		NotificationButton,
		NotificationModal
	},
	mounted() {
		setInterval(() => {
			this.$store.dispatch('getNotifications')
		}, 10000)
	}
}
