<template>
	<div class="items-list__item" @click="emitClick" :class="{ 'items-list__item-active': isActive }">
		<slot name="icon"></slot>
		<slot name="text"></slot>
		<div class="actions" v-if="interactive">
			<i class="bx bx-arrow-from-bottom"></i>
			<i class="bx bx-arrow-from-top"></i>
			<i class="bx bx-hide"></i>
		</div>
		<slot name="appender"></slot>
	</div>
</template>

<script>
export default {
	props: {
		item: {
			required: true,
			type: Object
		},
		isActive: {
			required: false,
			type: Boolean
		},
		interactive: {
			required: false,
			type: Boolean
		}
	},

	methods: {
		emitClick() {
			this.$emit('click', this.item)
		}
	}
}
</script>

<style lang="scss" scoped>

@import 'resources/sass/variables';

.items-list__item {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	background: white;
	border-radius: 5px;
	padding: 5px 10px;
	cursor: pointer;
	transition: .3s;
	margin: 3px;
	border: 1px transparent solid;

	&:hover {
		background: $green-50;
	}

	.bx {
		margin-right: 3px;
		margin-top: 4px;
		font-size: 15px;
	}

	&-active {
		background: $green-30;
	}
}
</style>
