import VueRouter from "vue-router"
import store from 'store/index'
import {DEFAULT_PAGE, DEFAULT_SECTION} from "../utils/constants";

const routes = [
    {
        path: '/login',
        name: 'login',
        component: require('views/Auth/Login').default,
    },
    {
        path: '/',
        name: 'home',
        component: require('views/Home').default,
        meta: {
            requiredAuth: true,
            hasHeader: true
        }
    },
    {
        path: '/pages',
        name: 'pages',
        component: require('views/Pages/Pages').default,
        meta: {
            requiredAuth: true,
            hasHeader: true
        }
    },
    {
        path: '/questions',
        name: 'questions',
        component: require('views/Questions').default,
        meta: {
            requiredAuth: true,
            hasHeader: true
        }
    },
    {
        path: '/pages/:id',
        name: 'pages',
        component: require('views/Pages/Pages').default,
        meta: {
            requiredAuth: true,
            hasHeader: true
        }
    },
    {
        path: '/sections',
        name: 'sections',
        component: require('views/Sections/Sections').default,
        meta: {
            requiredAuth: true,
            hasHeader: true
        }
    },
    {
        path: '/sections/:id',
        name: 'sections',
        component: require('views/Sections/Sections').default,
        meta: {
            requiredAuth: true,
            hasHeader: true
        }
    },
    {
        path: '/sliders',
        name: 'sliders',
        component: require('views/Sliders/Sliders').default,
        meta: {
            requiredAuth: true,
            hasHeader: true
        }
    },
    {
        path: '/sliders/:id',
        name: 'sliders',
        component: require('views/Sliders/Sliders').default,
        meta: {
            requiredAuth: true,
            hasHeader: true
        }
    }
]

const router = new VueRouter ({
    routes
})

router.beforeEach(async (to, from, next) => {
    if (from.path.includes('/sections')) {
        store.commit('SET_ACTIVE_SECTION', DEFAULT_SECTION)
    }

    if (from.path.includes('/pages')) {
        store.commit('SET_ACTIVE_PAGE', DEFAULT_PAGE)
    }

    if (to.path === '/') {
        next('/pages')
    }
    if (to.meta.requiredAuth) {
        const hasToken = await store.dispatch('checkToken')
        console.log(hasToken)
        if (hasToken) {
            next()
        } else {
            next('/login')
        }
    } else {
        next()
    }
})

export default router
