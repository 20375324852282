<template>
    <div>
        <h1>Home</h1>
        <PagesTree :tree="pagesTree"></PagesTree>
    </div>
</template>

<script>
import PagesTree from "components/Pages/PagesTree";
import { mapGetters } from "vuex"
export default {
    components: {
        PagesTree
    },

    computed: {
        ...mapGetters([
            'pagesTree'
        ])
    },

    created () {
        this.$store.dispatch('getPagesTree')
    }
}
</script>
