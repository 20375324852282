import Vue from "vue";
import Api from "api"
const api = new Api();


export const sectionsModule = {
    state: () => ({
        list: [],
        activeSection: {}
    }),

    getters: {
        sectionsList: state => state.list,
        activeSection: state => state.activeSection
    },

    mutations: {
        SET_SECTION_LIST (state, list) {
            Vue.set(state, 'list', list)
        },
        SET_ACTIVE_SECTION (state, section) {
            Vue.set(state, 'activeSection', section)
        },
        SET_SECTION_VALUE (state, { key, value }) {
            Vue.set(state.activeSection, key, value)
        }
    },

    actions: {
        getSectionsList ({ commit }) {
            return new Promise(async (resolve, reject) => {
                try {
                    const {data} = await api.get('/sections')
                    commit('SET_SECTION_LIST', data)
                    resolve(data)
                } catch (error) {
                    reject(error)
                }
            })
        },
        getSectionById ({ commit }, id) {
            return new Promise(async (resolve, reject) => {
                try {
                    const {data} = await api.get(`/sections/${id}`)
                    commit('SET_ACTIVE_SECTION', data)
                    resolve()
                } catch (error) {
                    reject(error)
                }
            })
        },

        removePageFromSection ({ dispatch }, { id, sectionId }) {
            return new Promise(async (resolve, reject) => {
                try {
                    const { data } = await api.delete(`/sections/${sectionId}/pages/${id}`)
                    resolve(data)
                } catch (error) {
                    reject(error)
                }
            })
        },

        createSection ({ dispatch }, section) {
            return new Promise(async (resolve, reject) => {
                try {
                    const { data } = await api.post('/sections', section)
                    await dispatch('getSectionsList')
                    resolve(data)
                } catch (error) {
                    reject(error)
                }
            })
        },

        updateSection ({ dispatch }, section) {
            return new Promise(async (resolve, reject) => {
                try {
                    const { data } = await api.put(`/sections/${section.id}`, section)
                    await dispatch('getSectionsList')
                    resolve(data)
                } catch (error) {
                    reject(error)
                }
            })
        },

        deleteSection (store, id) {
            return new Promise(async (resolve, reject) => {
                try {
                    await api.delete(`/sections/${id}`)
                    resolve()
                } catch (error) {
                    reject(error)
                }
            })
        }
    }
}
