<template>
	<div class="search-wrapper">
		<b-form-input
			class="mb-2"
			id="input-search"
			:value="searchValues.text"
			@input="(val) => setValue('text', val)"
			placeholder="Название для поиска"
		></b-form-input>
		<b-form-select
			:value="searchValues.field"
			:options="searchFields"
			@input="(val) => setValue('field', val)"
		>
		</b-form-select>
	</div>
</template>

<script>
export default {
	props: {
		value: {
			required: false,
			type: [ Object, null ]
		},
		searchFields: {
			required: true,
			type: Array
		}
	},
	computed: {
		searchValues () {
			return {
				text: this.value?.text,
				field: this.value?.field
			}
		}
	},
	model: {
		prop: 'value',
		event: 'search'
	},
	methods: {
		setValue (field, value) {
			this.$emit('search', {
				...this.value,
				[field]: value
			})
		}
	}
}
</script>


<style lang="scss" scoped>
.search-wrapper {
	display: flex;
	flex-wrap: wrap;
}
</style>
