import Vue from "vue";
import Api from "api"
import {asyncForEach} from "../../utils/helpers";
const api = new Api();

export const pageModule = {
	state: () => ({
		tree: [],
		list: [],
		activePage: {},
		pageOptions: {},
		pageTags: []
	}),

	getters: {
		pageOptions: state => state.pageOptions,
		pagesTree: state => state.tree,
		pagesList: state => state.list,
		activePage: state => state.activePage,
		pageTags: state => state.pageTags
	},

	mutations: {
		SET_TAG_NAME (state, tag) {
			state.pageTags.forEach(item => {
				if (tag.id === item.id) {
					item.name = tag.name
				}
			})
		},
		SET_TAGS (state, tags) {
			Vue.set(state, 'pageTags', tags)
		},
		SET_PAGE_OPTION (state, { field, value }) {
			console.log({ field, value })
			Vue.set(state.pageOptions, field, value)
		},
		SET_PAGES_TREE(state, tree) {
			Vue.set(state, 'tree', tree)
		},
		SET_PAGES_LIST(state, list) {
			Vue.set(state, 'list', list)
		},
		SET_ACTIVE_PAGE(state, data) {
			Vue.set(state, 'activePage', {...state.activePage, ...data})
		},
		SET_ACTIVE_PAGE_ID(state, id) {
			Vue.set(state.activePage, 'id', id)
		},
		SET_PAGE_VALUE(state, {field, value}) {
			if (state.activePage) {
				Vue.set(state.activePage, field, value)
			}
		},
		PREPEND_PAGE(state, page) {
			Vue.set(state, 'list', [page, ...state.list])
		},
		UPDATE_PAGES_LIST_PAGE(state, page) {
			if (state.list.find(item => item.id === page.id)) {
				const pages = state.list.map(el => {
					if (el.id === page.id) {
						el = page
					}
					return el
				})
				Vue.set(state, 'list', pages)
			}
		},
		DELETE_PAGE_FROM_LIST(state, id) {
			Vue.set(state, 'list', state.list.filter(page => page.id !== id))
		},
		UPDATE_ACTIVE_PAGE_FILE(state, data) {
			const files = state.activePage.links_files
			const updatedFiles = files.map(file => {
				if (file.id === data.id) {
					return {
						...file,
						...data
					}
				} else {
					return file
				}
			})
			console.log(updatedFiles)
			Vue.set(state.activePage, 'links_files', updatedFiles)
		}
	},

	actions: {
		updateTag (store, { id, name }) {
			return new Promise(async (resolve, reject) => {
				try {
					await api.post(`/page-tags/${id}`, { name })
				} catch (error) {
					reject(error)
				}
			})
		},
		getPagesTree({commit}) {
			return new Promise(async (resolve, reject) => {
				try {
					const {data} = await api.get('/pages')
					commit('SET_PAGES_TREE', data)
					resolve(data)
				} catch (error) {
					reject(error)
				}
			})
		},

		getPagesList(store, {type = null, searchType = null, searchText, searchField, limit = 100000, offset = 0}) {
			return new Promise(async (resolve, reject) => {
				try {
					let data
					let params = new URLSearchParams({
						limit: limit.toString(),
						offset: offset.toString()
					});
					if (searchText) {
						params.fill({
							search_text: searchText,
							search_field: searchField || 'name_ru',
						})
					}
					switch (searchType) {
						case 'tag': {
							const response = await api.get(`/pages/special/${type}?${params.toString()}`)
							data = response?.data || []
							break
						}
						case 'type': {
							params.fill({
								type
							})
							const response = await api.get('/pages?' + params.toString())
							data = response?.data || []
							break
						}
						default: {
							const response = await api.get('/pages?' + params.toString())
							data = response?.data || []
							break
						}
					}
					resolve(data)
				} catch (error) {
					reject(error)
				}
			})
		},

		getPageBySlugOrId ({ commit }, value) {
			return new Promise(async (resolve, reject) => {
				try {
					const { data } = Boolean(value.match(/^\d+$/)) ?
						await api.get(`/pages/${value}`) :
						await api.get(`/pages/by-slug/${value}`)
					const linksData = await api.get(`/pages/${data.id}/links`)
					console.log(linksData)
					commit('SET_ACTIVE_PAGE', {
						...data,
						tag_id: data.tag_id ? +data.tag_id : null,
						links: linksData.data.links,
						links_files: linksData.data.links_files
					})
					commit('SET_ACTIVE_PAGE_ID', data.id)
					resolve({
						...data,
						tag_id: data.tag_id ? +data.tag_id : null
					})
				} catch (error) {
					reject(error)
				}
			})
		},

		getPageById({commit}, id) {
			return new Promise(async (resolve, reject) => {
				try {
					const {data} = await api.get(`/pages/${id}`)
					commit('SET_ACTIVE_PAGE', {
						...data,
						tag_id: data.tag_id ? +data.tag_id : null
					})
					commit('SET_ACTIVE_PAGE_ID', id)
					resolve()
				} catch (error) {
					reject(error)
				}
			})
		},

		updatePage(store, page) {
			return new Promise(async (resolve, reject) => {
				try {
					const {data} = await api.put(`/pages/${page.id}`, page)
					resolve(data)
				} catch (error) {
					reject(error)
				}
			})
		},

		removeSectionPages(store, {sectionId, pages}) {
			return new Promise(async (resolve, reject) => {
				try {
					const {data} = await api.put(`/pages/${parent}/children`, {removed: pages, section_id: sectionId})
					resolve(data)
				} catch (error) {
					reject(error)
				}
			})
		},

		setPageChildren(store, {children, parentId, sectionId}) {
			return new Promise(async (resolve, reject) => {
				try {
					const parent = parentId || 0
					const {data} = await api.put(`/pages/${parent}/children`, {
						children,
						section_id: sectionId,
						parent_id: parentId
					})
					resolve(data)
				} catch (error) {
					reject(error)
				}
			})
		},

		createPage(store, page) {
			return new Promise(async (resolve, reject) => {
				try {
					const {data} = await api.post(`/pages`, page)
					resolve(data)
				} catch (error) {
					reject(error)
				}
			})
		},

		deletePage({commit}, id) {
			return new Promise(async (resolve, reject) => {
				try {
					const {data} = await api.delete(`/pages/${id}`)
					resolve(data)
				} catch (error) {
					reject(error)
				}
			})
		},

		updateLinksOrders(store, {target, sibling}) {
			return new Promise(async (resolve, reject) => {
				try {
					const {data} = await api.put(`/links`, {target, sibling})
					resolve(data)
				} catch (error) {
					console.log(error)
					reject(error)
				}
			})
		},

		addFile(store, file) {
			return new Promise(async (resolve, reject) => {
				try {
					const {data} = await api.post('/files', file)
					resolve(data)
				} catch (error) {
					reject(error)
				}
			})
		},

		deleteDocument(store, {bannerUrl, dir}) {
			return new Promise(async (resolve, reject) => {
				try {
					if (bannerUrl) {
						await api.delete(`/documents?url=${bannerUrl}` + `${dir ? '&dir=' + dir : ''}`)
					}
					resolve()
				} catch (error) {
					reject(error)
				}
			})
		},

		deleteFile(store, {id, link}) {
			return new Promise(async (resolve, reject) => {
				try {
					await api.delete(`/files/${id}` + (link ? `?link=${link}` : ''))
					resolve()
				} catch (error) {
					reject(error)
				}
			})
		},

		updateFiles(store, files) {
			return new Promise(async (resolve, reject) => {
				try {
					await asyncForEach(files, async (file) => {
						await api.post('/files', file)
					})
					resolve()
				} catch (error) {
					reject(error)
				}
			})
		},

		getTags(store) {
			return new Promise(async (resolve, reject) => {
				try {
					const {data} = await api.get('/page-tags')
					resolve(data)
				} catch (error) {
					reject(error)
				}
			})
		},

		addTag(store, name) {
			return new Promise(async (resolve, reject) => {
				try {
					const {data} = await api.post('/page-tags', {name})
					resolve(data)
				} catch (error) {
					reject(error)
				}
			})
		}
	}
}
