export default class LinkDropdown {
	constructor (editor) {
		this.editor = editor
	}

	createHTML () {
		return `<div>{{ LINKS:dropdown[] }}</div>`
	}

	onAction () {
		this.editor.insertContent(this.createHTML(), {})
	}

	get options () {
		const button = this
		return {
			type: 'menuitem',
			text: 'Выпадающее меню',
			onAction: button.onAction.bind(button)
		}
	}
}
