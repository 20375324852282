<template>
  <div class="pages">
    <ItemsSidebar
      :items="pagesList"
      empty-list-text="Страницы отсутствуют"
      title="Страницы"
      @scrollend="getPages"
			:search="search"
      @search="val => setValue('search', val)"

			:filter="filter.value"
			@filter="val => setValue('filter', val)"
      :prevent-default-search="true"
			:filterFields="pagesFilterFields"
    >
      <template v-slot:default="{ item }">
				<ItemsSidebarItem
						:is-active="isActive(item)"
						:item="item"
						@click="selectPage(getPageSlug(item))"
					>
					<template #icon>
						<i class="bx bx-book-content" v-if="item.type === 'custom'"></i>
						<i class="bx bx-news" v-else-if="item.type === 'news'"></i>
						<i class="bx bx-star" v-else-if="item.type === 'special'"></i>
					</template>
					<template #text>
						<span class="text-overflow">{{
								item.name_ru || item.name_en
							}}</span>
					</template>
					<template #appender>
						<span class="slug ml-auto" :title="`/${getPageSlug(item)}`">
							<router-link :to="`/pages/${getPageSlug(item)}`">
								{{ `/${getPageSlug(item)}` }}
							</router-link>
						</span>
					</template>
				</ItemsSidebarItem>
      </template>
    </ItemsSidebar>
    <div>
      <PagesContent
        :page="page"
        :is-loading="isLoading"
        :is-edit="isEdit"
        v-if="page"
      ></PagesContent>
      <b-alert variant="danger" v-else>
        <strong>Страница не выбрана!</strong>
        <span>Для редактирования выберете страницу в левом меню</span>
      </b-alert>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import PagesContent from "components/Pages/PagesContent";
const PagesList = () => import("components/Pages/PagesList");
const PagesListItem = () => import("components/Pages/PagesListItem");
const ItemsSidebar = () => import("components/Common/ItemsSidebar");
const ItemsSidebarItem = () => import("components/Common/ItemsSidebarItem");
import { DEFAULT_PAGE, PAGE_TYPES } from "utils/constants";
import { pagination } from "mixins";
import { getPageSlug } from "utils/helpers";
import {PAGES_FILTER_ITEMS} from "../../utils/constants";

export default {
  components: {
    PagesList,
    PagesContent,
    PagesListItem,
    ItemsSidebar,
    ItemsSidebarItem,
  },
  mixins: [pagination],
  mounted() {
    this.setLoading(false);
    this.getPages();
    this.setActivePage();
  },
  beforeDestroy() {
    this.$store.commit("SET_PAGES_LIST", []);
    this.pagination.page = 1;
  },
  computed: {
    ...mapGetters(["pagesList", "activePage", 'pageOptions']),
    isEdit() {
      return Boolean(this.$route.params.id);
    },
    page() {
      return this.activePage;
    },
  },

  data: () => {
    return {
      isLoading: false,
      search: {},
			filter: {
				value: null,
				searchType: null
			},
			pagesFilterFields: PAGES_FILTER_ITEMS
    };
  },

  watch: {
    "$route.params.id"(id) {
			this.$set(this, 'search', { text: null, field: null })
      this.setLoading(true);
      if (this.isEdit) {
        this.setActivePage();
      } else {
        this.SET_ACTIVE_PAGE_ID(null);
        this.SET_ACTIVE_PAGE(DEFAULT_PAGE);
      }
    },
		'pageOptions.links.filterPages' (filter) {
			this.getPages()
		},
    page: {
      deep: true,
      handler: function () {
        this.setLoading(false);
      },
    },
  },

  methods: {
    ...mapMutations(["SET_ACTIVE_PAGE_ID", "SET_ACTIVE_PAGE"]),
    ...mapActions({
      _getPagesList: "getPagesList",
    }),
		getPageSlug,
    setLoading(isLoading) {
      this.$store.commit("SET_LOADING", isLoading);
    },
    async getPages() {
      if (this.allItemsLoaded) {
        return;
      }
      this.setLoading(true);
      try {
        const pages = await this.load(this._getPagesList, {
					type: this.filter?.value,
					searchType: this.filter?.searchType,
					searchText: this.search.text,
					searchField: this.search.field,
          page: this.pagination.page,
        });
				if (this.search) {
					this.allItemsLoaded = false;
				}
        this.$store.commit("SET_PAGES_LIST", [...this.pagesList, ...pages]);
      } catch (error) {
        console.error(error);
        this.$notify({
          type: "error",
          group: "messages",
          title: "Ошибка",
          text: "Не удалось загрузить страницы!",
        });
      }
      this.setLoading(false);
    },
    async setActivePage() {
      if (this.isEdit) {
        try {
          const page = await this.$store.dispatch("getPageBySlugOrId", this.$route.params.id);
          this.SET_ACTIVE_PAGE_ID(page.id);
        } catch (error) {
          console.error(error);
          this.$notify({
            type: "error",
            group: "messages",
            title: "Ошибка",
            text: "Указанная страница не найдена!",
          });
          await this.$router.push("/pages");
        }
      } else {
        this.SET_ACTIVE_PAGE(DEFAULT_PAGE);
      }
    },
		setValue (field, val) {
			this.$set(this, field, val)
			this.resetPages()
			this.getPages()
		},
		resetPages () {
			this.$store.commit("SET_PAGES_LIST", []);
			this.pagination.page = 1;
			this.pagination.offset = 0;
			this.allItemsLoaded = false;
		},
    selectPage(id) {
      this.$router.push(`/pages/${id}`);
    },
    isActive(item) {
			if (this.activePage.id) {
				return +this.activePage.id === item.id || this.activePage.id === item.slug_ru;
			} else {
				return false
			}
    },
  },
};
</script>

<style lang="scss" scoped>
.pages {
  display: grid;
  grid-template-columns: minmax(300px, 1fr) 6fr;
  grid-gap: 50px;
  padding: 30px;
}
</style>
