<template>
    <div class="login-page">
        <form-auth
            title="Вход"
            :buttons="buttons"
            :items="items"
            :errors="errors"
            :disabled="isLoading"
            @auth:login="loginUser"
            @redirect:register="redirect"
            @reset:error="resetError"
        >
        </form-auth>
    </div>
</template>

<script>
import FormAuth from "components/FormAuth";
import {AUTH_LOGIN_BUTTONS, AUTH_LOGIN_FIELDS} from "utils/constants";
const { getState } = require('utils/helpers')

export default {
    data: () => {
        return {
            errors: {},
            isLoading: false
        }
    },

    components: {
        FormAuth
    },

    computed: {
        buttons () {
            return AUTH_LOGIN_BUTTONS
        },
        items () {
            return AUTH_LOGIN_FIELDS
        }
    },

    methods: {
        getState,
        async loginUser ({ email, password }) {
            this.isLoading = true
            try {
                console.log('login', { email, password })
                await this.$store.dispatch('loginUser', { email, password, router: this.$router })
            } catch (response) {
                console.error(response.data.errors)
                if (response.data.errors) {
                    this.$set(this, 'errors', response.data.errors)
                }
                this.$notify({
                    group: 'messages',
                    type: 'error',
                    title: 'Ошибка',
                    text: 'Не удалось выполнить вход'
                })
                this.isLoading = false
            }
        },
        redirect () {
            this.$router.push('/register')
        },
        resetError (field) {
            delete this.errors[field]
        }
    }
}
</script>

<style lang="scss" scoped>
.login-page {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>
