export default {
	app: {
		url: process.env.MIX_APPLICATION_URL || 'http://localhost:3000',
		front_url: process.env.MIX_FRONT_URL || 'http://localhost:4000'
	},
	news_item: {
		tag: process.env.MIX_NEWS_ITEM_TAG || 'news_item',
		id: process.env.MIX_NEWS_ITEM_TAG_ID ? parseInt(process.env.MIX_NEWS_ITEM_TAG_ID) : null
	},
}
