<template>
  <div class="sections">
    <ItemsSidebar
      :items="slidersList"
			:has-search="false"
      empty-list-text="Слайдеры отсутствуют"
      title="Слайдеры"
    >
      <template v-slot:default="{ item }">
        <ItemsSidebarItem
          :is-active="isActive(item.id)"
          :item="item"
          @click="selectSlider(item.id)"
        >
          <template #text>
            <span class="text-overflow">{{
              item.name
            }}</span>
          </template>
					<template #appender>
							<span class="slug ml-auto" :title="`/${item.id}`">
								<router-link :to="`/sliders/${item.id}`">
									{{ `/${item.id}` }}
								</router-link>
							</span>
					</template>
        </ItemsSidebarItem>
      </template>
    </ItemsSidebar>
    <div class="section-data">
      <SliderSettings
        :slider="activeSlider"
        :is-edit="isEdit"
        @input="setSectionValue"
      ></SliderSettings>
      <SliderContent
        class="mt-2"
        :is-edit="Boolean($route.params.id)"
        :slider="activeSlider"
        ref="content"
        @sliders:refresh="getSliders"
        @slider:update="setActiveSlider"
        @input="setSectionValue"
      ></SliderContent>
    </div>
  </div>
</template>

<script>
const ItemsSidebar = () => import("components/Common/ItemsSidebar");
const ItemsSidebarItem = () => import("components/Common/ItemsSidebarItem");
const SliderSettings = () => import("components/Sliders/SliderSettings");
const SliderContent = () => import("components/Sliders/SliderContent");
import { DEFAULT_SLIDER } from "utils/constants";
import { mapGetters, mapMutations, mapActions } from "vuex";
import { pagination } from "mixins";

export default {
  components: {
    ItemsSidebar,
    ItemsSidebarItem,
    SliderContent,
    SliderSettings,
  },
  mounted() {
    this.getSliders();
    this.setActiveSlider();
  },

  computed: {
    ...mapGetters(["slidersList", "activeSlider"]),
    isEdit() {
      return !isNaN(parseInt(this.$route.params.id));
    },
  },

  watch: {
    "$route.params.id"(id) {
      this.$store.commit("SET_LOADING", true);
      if (this.isEdit) {
        if (!this.$route.query.hasOwnProperty("children")) {
          this.setActiveSlider();
        }
      } else {
        this.SET_ACTIVE_SLIDER({ id: null, ...DEFAULT_SLIDER });
      }
			this.$store.commit("SET_LOADING", false);
    },
    section: {
      deep: true,
      handler: function () {
        this.$store.commit("SET_LOADING", false);
      },
    },
  },

  methods: {
    ...mapMutations(["SET_ACTIVE_SLIDER", "SET_SLIDER_VALUE"]),
    ...mapActions({
      _getSlidersList: "getSlidersList",
    }),
    async getSliders() {
      this.$store.commit("SET_LOADING", true);
      try {
        await this.$store.dispatch("getSlidersList");
      } catch (error) {
        console.error(error);
        this.$notify({
          type: "error",
          group: "messages",
          title: "Ошибка",
          text: "Не удалось загрузить слайдеры!",
        });
      } finally {
        this.$store.commit("SET_LOADING", false);
      }
    },
    async setActiveSlider() {
      try {
        this.$store.commit("SET_LOADING", true);
        if (this.isEdit) {
          console.log(this.$route.query.children);
          if (this.$route.query.hasOwnProperty("children")) {
            console.log(this.$refs["content"]);
            await this.$refs["content"]?.addPages({
              children: this.$route.query.children,
              parentId: this.$route.query.parentId,
            });
          }
          await this.$store.dispatch("getSliderById", this.$route.params.id);
          this.$store.commit("SET_LOADING", false);
        } else {
          this.SET_ACTIVE_SLIDER(DEFAULT_SLIDER);
        }
        this.$store.commit("SET_LOADING", false);
      } catch (error) {
        console.error(error);
        this.$notify({
          type: "error",
          group: "messages",
          title: "Ошибка",
          text: "Не удалось загрузить слайдер!",
        });
        this.SET_ACTIVE_SLIDER(DEFAULT_SLIDER);
        await this.$router.push("/sliders");
      }
    },
    setSectionValue({ key, value }) {
      this.SET_SLIDER_VALUE({ field: key, value });
    },
    resetNode() {
      this.$set(this, "currentNode", { id: null });
    },
    isActive(id) {
      return +this.activeSlider.id === id;
    },
    selectSlider(id) {
      this.$router.push(`/sliders/${id}`);
    },
  },
};
</script>

<style lang="scss" scoped>
.sections {
  display: grid;
  grid-template-columns: minmax(300px, 1fr) 6fr;
  grid-gap: 50px;
  padding: 30px;
}
</style>
