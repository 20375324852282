const spoilerDialogConfig = {
	title: 'Добавление спойлера',
	body: {
		type: 'panel',
		items: [
			{
				type: 'textarea',
				name: 'spoilerContentShown',
				label: 'Видимый текст спойлера',
			},
			{
				type: 'textarea',
				name: 'spoilerContentHidden',
				label: 'Скрытый текст спойлера',
			},
			{
				type: 'input',
				name: 'buttonExpand',
				label: 'Кнопка разворачивания текста',
			},
			{
				type: 'input',
				name: 'buttonCollapse',
				label: 'Кнопка сворачивания текста',
			},
		]
	},
	buttons: [
		{
			type: 'cancel',
			name: 'closeButton',
			text: 'Отменить добавление'
		},
		{
			type: 'submit',
			name: 'submitButton',
			text: 'Добавить спойлер',
			buttonType: 'primary'
		}
	],
	initialData: {
		spoilerContentShown: '',
		spoilerContentHidden: '',
		buttonExpand: 'Показать все',
		buttonCollapse: 'Свернуть'
	}
}

export default class SpoilerDialog {
	/**
	 * @param { Editor } editor
	 */
	constructor (editor) {
		this.editor = editor
		this.config = spoilerDialogConfig
	}

	createHTML (data) {
		const {
			spoilerContentShown,
			spoilerContentHidden,
			buttonExpand = 'Показать все',
			buttonCollapse = 'Свернуть'
		} = data
		console.log({
			spoilerContentShown,
			spoilerContentHidden
		})
		const spoilerId = `spoiler_${parseInt((Math.random() * (9999 - 1111) + 1111))}`
		return `
			<div></div>
			<div class="spoiler">
					<input type="checkbox" id="${spoilerId}" class="spoiler-toggle">
					<div class="spoiler-content spoiler-content-shown">
						<div>
							${spoilerContentShown || 'Видимый текст'}
							<div class="bottom-shadow"></div>
						</div>
					</div>
					<div class="spoiler-content spoiler-content-hidden">
						<div>${spoilerContentHidden || 'Скрытый текст'}</div>
					</div>
					<div class="toggle-wrapper">
							<label for="${spoilerId}" class="spoiler-toggle-label">
								<span class="label-expand">${buttonExpand}</span>
								<span class="label-collapse">${buttonCollapse}</span>
							</label>
					</div>
			</div>
			<div></div>
		`
	}

	onSubmit (api) {
		const data = api.getData()
		const html = `<div>${this.createHTML(data)}</div>`
		this.editor.insertContent(html, {})
		api.close()
	}

	open () {
		const config = {
			...spoilerDialogConfig,
			onSubmit: this.onSubmit.bind(this)
		}
		this.editor.windowManager.open(config, {})
	}
}
