<template>
    <div class="form-auth">
        <b-card
            border-variant="primary"
            :header="title"
            header-bg-variant="primary"
            header-text-variant="white"
            align="center"
        >
            <div v-for="item in items"
                 :key="item.id"
                 class="form-input-field">
                <label :for="item.id">{{ item.text }}</label>
                <b-form-input
                    :aria-describedby="'feedback_' + item.id"
                    :state="getState(item.id, errors)"
                    :id="item.id"
                    v-model="user[item.field]"
                    :type="item.type"
                    @input="resetError(item.field)"
                >
                </b-form-input>
                <b-form-invalid-feedback
                    :id="'feedback_' + item.id">
                    {{ invalidFeedbackMessage(item.id) }}
                </b-form-invalid-feedback>
            </div>

            <div class="form-buttons">
                <b-button
                    class="form-buttons__item"
                    v-for="button in buttons"
                    :key="button.id"
                    :variant="button.variant"
                    :disabled="disabled"
                    @click="$emit(button.event, user)">
                    {{ button.text }}
                </b-button>
            </div>
        </b-card>
    </div>
</template>

<script>

export default {
    props: {
        title: {
            required: true,
            type: String
        },
        buttons: {
            required: true,
            type: Array
        },
        items: {
            required: true,
            type: Array
        },
        errors: {
            required: false,
            type: Object
        },
        disabled: {
            required: false,
            type: Boolean
        }
    },
    data: () => {
        return {
            user: {
                name: '',
                email: '',
                password: ''
            }
        }
    },

    methods: {
        getState (field, errors) {
            return errors[field] ? false : null
        },
        resetError (field) {
            this.$emit('reset:error', field)
        },
        invalidFeedbackMessage (id) {
            const errors = this.errors[id]
            return Array.isArray(errors) ? errors.join(', ') : errors
        }
    }
}
</script>

<style lang="scss" scoped>
.form-auth {
    width: 500px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .form-input-field {
        text-align: left;
        margin-top: 10px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }

    .form-buttons {
        margin-top: 20px;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;

        &__item {
            margin-left: 3px;
        }
    }
}
</style>
