/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

import Vue from "vue"
import VueRouter from "vue-router"

window.Vue = require('vue')
Vue.use(VueRouter)

require('./bootstrap');

window.Vue = require('vue').default;

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

Vue.component('vue-app', require('./App.vue').default);

// Vue.component('login-page',  require('views/Auth/Login').default)
// Vue.component('form-auth',  require('components/FormAuth').default)

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

import router from './router/router.js'
import BootstrapVue from 'bootstrap-vue'

Vue.use(BootstrapVue)

import store from './store/index'

import Notifications from 'vue-notification'

Vue.use(Notifications)

import Api from "./api";

Vue.prototype.$api = new Api()


import config from "./utils/config";

Vue.prototype.$config = config

import helpers from "./utils/helpers";

Vue.prototype.$helpers = helpers


import {contenteditable} from "./directives/contenteditable";

Vue.directive('contenteditable', contenteditable)

URLSearchParams.prototype.fill = function (entries) {
	console.log(entries)
	for (let key in entries) {
		const value = entries[key]
		if (value) {
			this.append(key, value.toString())
		}
	}
}

const app = new Vue({
	el: '#app',
	router,
	store
});

