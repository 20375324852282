<template>
    <div class="questions">
        <ItemsSidebar
            class="pages-list"
            :items="questions"
            empty-list-text="Вопросы отсутствуют"
            title="Список вопросов"
            :has-search="false"
        >
            <template v-slot:default="{ item }">
                <ItemsSidebarItem
                    :is-active="isQuestionSelected(item.id)"
                    :item="item"
                    @click="selectQuestion">
                    <template #text>
                        <div class="question-item" :style="{ background: item.answer ? '#3490dc20' : '#e3342f20' }" style="padding: 5px; border-radius: 3px; ">
                            <div class="question-item_head">
                                <span class="font-weight-bold">{{ item.personal_data }}</span>
                                <span>{{ item.phone }}</span>
                                <span>{{ item.email }}</span>
                            </div>
                            <div class="question-item_body">
                                <b-form-input id="question-text" :value="item.text" trim disabled></b-form-input>
                                <span class="text-black-50 d-flex justify-content-between">
                                    <span>Задан: </span>
                                    <span>{{ parseDateTime(item.created_at, true) }}</span>
                                </span>
                            </div>
                        </div>
                    </template>
                </ItemsSidebarItem>
            </template>
        </ItemsSidebar>
        <QuestionAnswerForm
            :question="selectedQuestion"
            :answer="answer"
            @answer:change="setAnswerValue"
            @question:change="setQuestionValue"
            @submit="save"
            @delete="deleteQuestion"
        ></QuestionAnswerForm>
    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import ItemsSidebar from "components/Common/ItemsSidebar";
import ItemsSidebarItem from "components/Common/ItemsSidebarItem";
import QuestionAnswerForm from "components/QuestionAnswerForm";
import { parseDateTime } from "utils/helpers";

export default {
    components: {
        ItemsSidebar,
        ItemsSidebarItem,
        QuestionAnswerForm
    },
    mounted () {
        this.loadQuestions()
        if (this.$route.query.id) {
            this.loadCurrentQuestion(this.$route.query.id)
        }
    },
    watch: {
        '$route.query.id' (id) {
            if (id) {
                this.loadCurrentQuestion(this.$route.query.id)
            } else {
                this.$set(this, 'selectedQuestion', {})
                this.$set(this, 'answer', {})
            }
        }
    },
    data: () => {
        return {
            isPublished: false,
            answer: {},
            questions: [],
            selectedQuestion: {},
            pagination: {
                totalCount: 0,
                pageNum: 1,
                perPage: 25
            }
        }
    },
    computed: {
        ...mapGetters([
            'getUser'
        ])
    },
    methods: {
        ...mapActions([
            'getQuestions',
            'loadQuestion',
            'updateQuestion',
            'updateAnswer',
            'removeQuestion',
            'removeAnswer'
        ]),
        parseDateTime,
        async deleteQuestion () {
            try {
                await Promise.allSettled([
                    this.removeAnswer({ question: this.selectedQuestion, answer: this.answer }),
                    this.removeQuestion(this.selectedQuestion)
                ])
                await this.loadQuestions()
                await this.$router.push({ path: '/questions' })
                this.$notify({
                    group: 'messages',
                    type: 'success',
                    title: 'Успех',
                    text: 'Вопрос удален'
                })
            } catch (error) {
                console.error(error)
                this.$notify({
                    group: 'messages',
                    type: 'error',
                    title: 'Ошибка',
                    text: 'Не удалось удалить вопрос'
                })
            }
        },
        async save () {
            try {
                const answer = {
                    ...this.answer,
                    personal_data: this.getUser.name,
                    question_id: this.selectedQuestion.id
                }
                const item = await this.updateAnswer(answer)
                this.$set(this, 'answer', item)
                await this.updateQuestion(this.selectedQuestion)
                await this.loadQuestions(this.isPublished)
                this.$notify({
                    group: 'messages',
                    type: 'success',
                    title: 'Успех',
                    text: 'Вопрос обновлен'
                })
            } catch (error) {
                console.error(error)
                this.$notify({
                    group: 'messages',
                    type: 'error',
                    title: 'Ошибка',
                    text: 'Не удалось обновить вопрос'
                })
            }
        },
        async loadCurrentQuestion (id) {
            try {
                const question = await this.loadQuestion(id)
                this.$set(this, 'selectedQuestion', question)
                this.$set(this, 'answer', question.answer)
            } catch (error) {
                console.error(error)
                this.$notify({
                    group: 'messages',
                    type: 'error',
                    title: 'Ошибка',
                    text: 'Не удалось получить вопрос'
                })
                await this.$router.push('/questions')
            }
        },
        setAnswerValue ({ key, value }) {
            if (this.answer) {
                this.$set(this.answer, key, value)
            } else {
                this.$set(this, 'answer', { [key]: value })
            }
        },
        setQuestionValue ({ key, value }) {
            this.$set(this.selectedQuestion, key, value)
        },
        selectQuestion ({ id }) {
            if (id === +this.$route.query.id) {
                this.$router.push({ path: '/questions' })
            } else {
                this.$router.push({ path: '/questions', query: {
                    id
                }})
            }
        },
        isQuestionSelected (id) {
            return this.selectedQuestion?.id === id
        },
        async loadQuestions () {
            try {
                const data = await this.getQuestions(this.isPublished)
                this.$set(this, 'questions', data.items)
                this.$set(this, 'pagination', data.totalCount)
            } catch (error) {
                console.error(error)
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.questions {
    margin: 30px;
    display: grid;
    grid-template-columns: 1fr 6fr;
    grid-gap: 20px;
    .question-item {
        &_head {

        }
        &_body {

        }
    }
}
</style>
