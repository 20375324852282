<template>
</template>

<script>

const lodash = require('lodash')

export default {

    props: {
        tree: {
            required: true,
            type: Array
        }
    },

    computed: {
        options() {
          return {
              propertyNames: {
                  text: 'name_ru',
                  children: 'children',
                  state: 'state'
              }
          }
        }
    },

    methods: {}
}
</script>

<style lang="scss" scoped>
</style>
